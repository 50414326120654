import { error } from "console";
import { axios } from "./ini";

export default class ClienteStore{

    public static getAllClientes(){
        return axios.get('cliente/getAllClientes')
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error)
        })
    }

    public static getCliente(rut:any){
        return axios.get('cliente/getCliente/'+rut)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            return error;
        })
    }

    public static getClientePorNombre(nombre:any){
        return axios.get('cliente/getClientePorNombre/'+nombre)
        .then((response:any)=>{

            return response;
        })
        .catch((error:any)=>{
            return error;
        })
    }

    public static addCliente(body:any){
        return axios.post('cliente/addCliente',body)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static editCliente(id:any,body:any){
        return axios.put('cliente/updateCliente/'+id,body )
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static deleteCliente(id:any){
        return axios.delete('cliente/deleteCliente/'+id)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static getAllComunas(){
        return axios.get('cliente/getAllComunas')
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }
}