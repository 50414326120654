import { axios } from "./ini";

export default class UsuarioStore{
    
    public static getAllUsuarios(){
        return axios.get('usuario/getAllUsers')
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{

        })
    }

    public static getUser(id:any){
        return axios.get('usuario/getUser/'+id)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{

        })
    }

    public static addUser(body:any){
        return axios.post('usuario/addUser',body)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{

        })
    }

    public static editUser(id:any,body:any){
        return axios.put('usuario/updateUser/'+id,body)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{

        })
    }

    public static deleteUser(id:any){
        return axios.delete('usuario/deleteUser/'+id)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{

        })
    }

    public static updatePassword(pass:any, id:any){
        return axios.put('usuario/cambiarContrasena/'+id,pass)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static getModalidades(){
        return axios.get('usuario/getModalidades')
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static getModalidadPorUsuario(usuario:any){
        return axios.get('usuario/getModalidadPorUsuario/'+usuario)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }
}