import React, { useState } from 'react'
import { Button, Space, Table, Modal, Row, Col, Select, Form, Input, notification, PageHeader } from 'antd';
import '../../css/usuario/usuario.css'
import UsuarioStore from '../../Store/usuarioStore';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import RolStore from '../../Store/rolStore';

interface IUserStates {
    dataSource: any;
    dataRol: any;
    isModalVisible: boolean;
    isModalEditVisible: boolean;
    isModalContrasena: boolean;
    nombre: string;
    aux: any;
    auxRol: any;
}

interface IUserProps {
    data: any;
}

const { confirm } = Modal;
const { Option } = Select;
let temp = [''];
let idUsuario = 0;

export default class MantenedorUsuarios extends React.Component<IUserProps, IUserStates>{

    constructor(props: any) {
        super(props);
        this.state = {
            dataSource: [],
            isModalVisible: false,
            isModalEditVisible: false,
            isModalContrasena: false,
            nombre: '',
            aux: [],
            auxRol: [],
            dataRol: []
        }

        this.editUser = this.editUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.showModalAddUser = this.showModalAddUser.bind(this);
        this.cambiarContrasena = this.cambiarContrasena.bind(this);
    }



    componentDidMount() {
        UsuarioStore.getAllUsuarios()
            .then((response: any) => {

                this.setState({ dataSource: response.data.listaUsuario })
            })
            .catch((error: any) => {

            });

        RolStore.getAllRoles()
            .then((response: any) => {

                this.setState({ dataRol: response.data.roles });
            })
            .catch((error: any) => {

            })
    }



    public editUser(params: any) {

        this.setState({ isModalEditVisible: true });
        const t = this.state.dataSource.find((item: any) => item.idUsuario == params.idUsuario);
        this.setState({ aux: t });

        const rol = this.state.dataRol.find((rol: any) => rol.idRol == params.fk_IdRol);
        this.setState({ auxRol: rol });

        idUsuario = params.idUsuario;
    }

    public deleteUser(record: any) {

        UsuarioStore.deleteUser(record.idUsuario)
            .then((response: any) => {
                const newArray = this.state.dataSource.filter((item: any) => item.idUsuario !== record.idUsuario);
                this.setState({
                    dataSource: newArray
                });

                notification.success({
                    message: 'Registro borrado!',
                    description: response.data.message
                })

            })
            .catch((error: any) => {
                console.log(error);
            })

    }

    public showDeleteConfirm(deleteFunction: any, record: any) {

        confirm({
            title: '¿Está seguro que desea eliminar al usuario ' + record.nombreUsuario + '?',
            icon: <ExclamationCircleOutlined />,
            //content: 'Some descriptions',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteFunction(record);

            },
            onCancel() {

            },
        });


    }

    public handleEdit = (body: any) => {

        JSON.stringify(body);
        UsuarioStore.editUser(idUsuario, body)
            .then((response: any) => {

                notification.success({
                    message: 'Registro editado!',
                    description: response.data.message
                });
                const bodyEdited = {
                    idUsuario,
                    ...body,

                }
                this.setState({ isModalEditVisible: false });

                // Modificando el Arrego de la tabla
                const newArray = this.state.dataSource.map((item: any) => {

                    if (item.idUsuario === bodyEdited.idUsuario) {
                        return bodyEdited;
                    }
                    else {
                        return item;
                    }
                });

                this.setState({
                    dataSource: newArray
                });

            })
            .catch((error: any) => {

            });
    }

    public handleOk = (body: any) => {

        JSON.stringify(body);
        UsuarioStore.addUser(body)
            .then((response: any) => {
                notification.success({
                    message: 'Registro existoso!',
                    description: response.data.message
                });

                const bodyAdded = {
                    id: response.data.idUsuario,
                    ...body,

                }
                this.setState({ isModalVisible: false })

                this.setState({
                    dataSource: [bodyAdded, ...this.state.dataSource]
                });

            })
            .catch((error: any) => {

            })

    };

    handleCancel = () => {
        this.setState({ isModalVisible: false });
        this.setState({ isModalEditVisible: false });
        this.setState({ isModalContrasena: false });
    };

    public showModalAddUser() {
        this.setState({ isModalVisible: true });
    }

    onChange(pagination: any, filters: any, sorter: any, extra: any) {
        console.log('params', pagination, filters, sorter, extra);
    }

    public showModalContrasena(record: any) {
        idUsuario = record.idUsuario;
        this.setState({ isModalContrasena: true });
    }

    public cambiarContrasena(body: any) {

        const pass = {
            password: body.password
        }

        UsuarioStore.updatePassword(pass, idUsuario)
            .then((response: any) => {
                this.setState({ isModalContrasena: false });
                notification.success({
                    message: 'Registro actualizado',
                    description: response.data.message
                });



            })
            .catch((error: any) => {
                console.log(error);
            })
    }


    render() {

        const columns = [
            { title: 'Rut', dataIndex: 'rut', key: 'rut' },
            { title: 'Usuario', dataIndex: 'nombreUsuario', key: 'nombreUsuario' },
            { title: 'Nombre', dataIndex: 'nombre', key: 'nombre' },
            { title: 'Apellido', dataIndex: 'apellido', key: 'apellido' },
            {
                title: 'Acciones', key: 'action', render: (text: any, record: any) => (<Space size="middle">
                    <Button type="link" onClick={() => this.editUser(record)}>Editar</Button>
                    <Button type="link" onClick={() => this.showDeleteConfirm(this.deleteUser, record)}>Eliminar</Button>
                </Space>)
            },
            {
                render: (text: any, record: any) => (
                    <Button type="primary" onClick={() => this.showModalContrasena(record)} danger shape="round">Cambiar contraseña</Button>
                )
            }
        ]

        return (
            <div>
                {/* <Row>
                    <Col>
                        <Select placeholder="Seleccionar persona"
                            showSearch
                            style={{ width: 200 }}
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {this.state.dataSource && this.state.dataSource.map((usu: any) => <Option value={usu.idUsuario}>{usu.nombre}</Option>)}
                        </Select>
                    </Col>
                </Row> */}

                <div style={{ backgroundColor: ' #f1efee ' }}>
                    <Row gutter={24}>
                        <Col>
                            <PageHeader title="Usuarios" />
                        </Col>
                        <Col span={20}>
                            <Button style={{ float: 'right', marginTop: '50px' }} type="primary" shape="round" className="btnAgregar" onClick={this.showModalAddUser}>Nuevo usuario</Button>
                        </Col>

                    </Row>
                    <Form >
                <Row>
                <Form.Item name="id">
                    
                <Input style={{marginLeft:'20px', width:'100%'}} type="text"  allowClear  placeholder="Ingresar rut"/>
                </Form.Item>
                <Form.Item>
                <Button style={{marginLeft:'30px'}}  type="primary" htmlType="submit" shape="round">Buscar</Button>
                </Form.Item>
                </Row>
                
                </Form>
                </div>
                
                <div style={{marginTop:'50px'}}>
                <Table columns={columns} dataSource={this.state.dataSource} onChange={this.onChange} />

                </div>

                
                <Modal title={"Cambiar contraseña"} visible={this.state.isModalContrasena} onCancel={this.handleCancel} destroyOnClose={true} footer={null}>
                    <Form onFinish={this.cambiarContrasena}>
                        <Form.Item
                            name="password"
                            label="Contraseña"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresar contraseña',
                                },
                            ]}
                        >

                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirmar contraseña"
                            dependencies={['password']}

                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor confirmar contraseña',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Las contraseñas no coinciden'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Cambiar contraseña
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal title="Nuevo usuario" visible={this.state.isModalVisible} destroyOnClose={true} onCancel={this.handleCancel} footer={null}>
                    <Form onFinish={this.handleOk}>
                        <Form.Item
                            name="nombre"
                            label="Nombre"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresar nombre de la persona',
                                },
                            ]}
                        >

                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="apellido"
                            label="Apellido"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresar apellido de la persona',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="rut"
                            label="Rut"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresar rut de la persona',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="nombreUsuario"
                            label="Usuario"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresar un nombre de usuario',
                                },
                            ]}
                        >
                            <Input type="text" minLength={3} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Contraseña"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresar una contraseña para el usuario',
                                },
                            ]}

                        >
                            
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="fk_IdRol"
                            label="Rol"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor seleccionar un rol para el usuario',
                                },
                            ]}

                        >
                            <Select>
                                {this.state.dataRol && this.state.dataRol.map((item: any) => <Option value={item.idRol} key={item.idRol}>{item.nombre}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Ingresar
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal title="Edición usuario" visible={this.state.isModalEditVisible} destroyOnClose={true} onCancel={this.handleCancel} footer={null}>
                    <Form onFinish={this.handleEdit}>
                        <Form.Item
                            name="nombre"
                            label="Nombre"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresar nombre de la persona',
                                },
                            ]}
                            initialValue={this.state.aux && this.state.aux.nombre}
                        >

                            <Input type="text" />
                        </Form.Item>
                        <Form.Item
                            name="apellido"
                            label="Apellido"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresar apellido de la persona',
                                },
                            ]}
                            initialValue={this.state.aux && this.state.aux.apellido}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="rut"
                            label="Rut"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresar rut de la persona',
                                },
                            ]}
                            initialValue={this.state.aux && this.state.aux.rut}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="nombreUsuario"
                            label="Usuario"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresar un nombre de usuario',
                                },
                            ]}
                            initialValue={this.state.aux && this.state.aux.nombreUsuario}
                        >
                            <Input type="text" minLength={5} />
                        </Form.Item>
                        {/* <Form.Item
                            name="password"
                            label="Contraseña"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresar una contraseña para el usuario',
                                },
                            ]}
                            initialValue = {this.state.aux && this.state.aux.password}
                        >
                            <Input.Password />
                        </Form.Item> */}

                        <Form.Item
                            name="fk_IdRol"
                            label="Rol"
                            initialValue={this.state.auxRol && this.state.auxRol.idRol}
                        >

                            <Select>
                                {this.state.dataRol && this.state.dataRol.map((item: any) => <Option value={item.idRol} key={item.idRol}>{item.nombre}</Option>)}
                            </Select>
                        </Form.Item>


                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Actualizar
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>




            </div>
        )
    }

}
