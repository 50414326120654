import React from 'react'

export default class MantenedorProductos extends React.Component <{},{}> {


    public render(){
        return (
            <div>
                
            </div>
        )
    }
    
}
