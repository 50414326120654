import { axios } from "./ini";


export default class ProductoStore{

    public static GetAllProductos()
    {
        return axios.get('producto/getAllProductos')
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            return error;
        })
    }

    public static GetPrecioProducto(lista:string, codigo:string, cantidad:any){
        return axios.get('producto/getPrecioProducto/'+lista+'/'+codigo+'/'+cantidad)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static GetStockSucursales(codigo:string){
        return axios.get('producto/getStockSucursales/'+codigo)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static GetImagenProducto(codigo:string){
        return axios.get('producto/getImagenProducto/'+codigo)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static GetProductoPorCodigo(codigo:string){
        return axios.get('producto/getProductoPorCodigo/'+codigo)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

}