import { Descriptions, Row } from 'antd';
import React from 'react'
import PedidoStore from '../../Store/pedidoStore';

interface IDetalleProps{
    data:any;
    //dataPedidos:any;
}

interface IDetalleState{
    dataPedido:any;
}

export default class DetallePedido extends React.Component<IDetalleProps,IDetalleState>{
    constructor(props:any){
        super(props);
        this.state={
            dataPedido:[]
        }
    }

    componentDidMount(){
        const codigo = this.props.data.location.state.NUDO;
        PedidoStore.getDetallePedido(codigo)
        .then((response:any)=>{
            this.setState({dataPedido:response.data.detallePedido});
            console.log(response);
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public render(){
        
        const cliente = this.state.dataPedido.cliente;
        const detalle = this.state.dataPedido.detalle;

        console.log(detalle);

        return (
            <div>
                <Descriptions title="Información cliente" bordered layout="vertical">
                    <Descriptions.Item label="Nombre cliente">{cliente && cliente.c.NOKOEN}</Descriptions.Item>
                    <Descriptions.Item label="Rut cliente">{cliente && cliente.c.KOEN}</Descriptions.Item>
                    <Descriptions.Item label="Dirección">{cliente && cliente.c.DIEN}</Descriptions.Item>
                </Descriptions>
                <br />
                <Descriptions title={`Detalle Pedido N° ${this.props.data.location.state.NUDO}`} column={5}  layout="vertical" bordered size="small">
                    <Descriptions.Item label="Cód producto">{detalle && detalle.map((item:any)=> <Row>{item.m.KOPRCT}</Row>)}</Descriptions.Item>
                    <Descriptions.Item label="Producto" >{detalle && detalle.map((item:any)=> <Row>{item.m.NOKOPR}</Row>)}</Descriptions.Item>
                    <Descriptions.Item label="Cantidad" >{detalle && detalle.map((item:any)=> <Row>{item.m.CAPRCO1}</Row>)}</Descriptions.Item>
                    <Descriptions.Item label="Precio" >{detalle && detalle.map((item:any)=> <Row>$ {item.m.PPPRNE}</Row>)}</Descriptions.Item>
                </Descriptions>
                
            </div>
        )
    }
    
}
