import { axios } from "./ini";

export default class PedidoStore{

    public static getPedidosCliente(rut:any){
        return axios.get('pedido/getPedidos/'+rut)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{

        })
    }

    public static addPedidoCliente(body:any){
        return axios.post('pedido/addPedidoCliente/',body)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static getDetallePedido(codigo:string){
        return axios.get('pedido/getDetallePedido/'+codigo)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static saveCotizacion(body:any){
        return axios.post('pedido/saveCotizacion/',body)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static getNotaVentaActual(){
        return axios.get('pedido/getNotaVentaActual')
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static getNumeroDocumento(tipoDocumento:any){
        return axios.get('pedido/getNumeroDocumento/'+tipoDocumento)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static getPedidoPorNumero(numeroPedido:any,tipoDocumento:any){
        return axios.get('pedido/getPedidoPorNumero/'+numeroPedido+"/"+tipoDocumento)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static getPedidoSeleccionado(tipoDocumento:any, numeroPedido:any){
        return axios.get('pedido/getPedidoSeleccionado/'+tipoDocumento+"/"+numeroPedido)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static getPedidosPendientes(rutCliente:any){
        return axios.get('pedido/getPedidosPendientes/'+rutCliente)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error)
        })
    }

    public static getDetallePedidoPendiente(numeroPedido:any){
        return axios.get('pedido/getDetallePedidoPendiente/'+numeroPedido)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static getPedidosPendientesUsuarios(usuario:any){
        return axios.get('pedido/getPedidosPendientesUsuario/'+usuario)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static getDetallePendienteUsuario(idPedido:any){
        return axios.get('pedido/getDetallePendienteUsuario/'+idPedido)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }

    public static saveDocumentoPendiente(body:any){
        return axios.post('pedido/saveDocumentoPendiente/',body)
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{
            console.log(error);
        })
    }
}