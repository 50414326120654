import { axios } from "./ini";

export default class RolStore{

    public static getAllRoles(){
        return axios.get('rol/getAllRoles')
        .then((response:any)=>{
            return response;
        })
        .catch((error:any)=>{

        })
    }
}