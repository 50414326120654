import React from 'react'
import { Table, Button, Row, Col, PageHeader, Input, Form, notification, Space, Drawer, Select } from 'antd';
import ClienteStore from '../../Store/clienteStore';
import Search from 'antd/lib/input/Search';
import { PlusOutlined } from '@ant-design/icons';
import { RegularExpression } from '../../Utils/RegularExpression';
import { Utilidades } from '../../Utils/Utilidades';

const { Option } = Select;

interface IClienteStates {
    dataSource: any;
    dataCliente: any;
    showForm: any;
    showFormEdit: any;
    dataComuna: any;
    editedCliente: any;
    regionSelected: any;
    comunaSelected: any;
}
export default class MantenedorClientes extends React.Component<{}, IClienteStates>{

    private resetForm: any;

    constructor(props: any) {
        super(props);
        this.state = {
            dataSource: [],
            dataCliente: [],
            showForm: false,
            showFormEdit: false,
            dataComuna: [],
            editedCliente: [],
            regionSelected: '',
            comunaSelected: ''
        }

        this.searchCliente = this.searchCliente.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.saveCliente = this.saveCliente.bind(this);
        this.validateRut = this.validateRut.bind(this);
        this.getCliente = this.getCliente.bind(this);
        this.loadRegion = this.loadRegion.bind(this);
    }

    componentDidMount() {
        ClienteStore.getAllClientes()
            .then((response: any) => {
                this.setState({ dataCliente: response.data.cliente });
            })
            .catch((error: any) => {

            });

        ClienteStore.getAllComunas()
            .then((response: any) => {
                this.setState({ dataComuna: response.data.comunas });
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    loadRegion(region: any) {
        region = region.trim();
        switch (region) {
            case 'RM':
                this.setState({ regionSelected: 'Región Metropolitana' });
                break;

            case '1':
                this.setState({ regionSelected: 'Tarapacá' });
                break;
            case '2':
                this.setState({ regionSelected: 'Antofagasta' });
                break;
            case '3':
                this.setState({ regionSelected: 'Atacama' });
                break;
            case '4':
                this.setState({ regionSelected: 'Coquimbo' });
                break;
            case '5':
                this.setState({ regionSelected: 'Valparaiso' });
                break;
            case '6':
                this.setState({ regionSelected: 'Ohiggins' });
                break;
            case '7':
                this.setState({ regionSelected: 'Maule' });
                break;
            case '8':
                this.setState({ regionSelected: 'Biobío' });
                break;
            case '9':
                this.setState({ regionSelected: 'La Araucanía' });
                break;
            case '10':
                this.setState({ regionSelected: 'Los Lagos' });
                break;
            case '11':
                this.setState({ regionSelected: 'Aysén del General Carlos Ibañez del Campo' });
                break;
            case '12':
                this.setState({ regionSelected: 'Magallanes y La Antártica Chilena' });
                break;
            case '14':
                this.setState({ regionSelected: 'Los Ríos' });
                break;
            case '15':
                this.setState({ regionSelected: 'Arica y Parinacota' });
                break;
            case '16':
                this.setState({ regionSelected: 'Ñuble' });
                break;

        }
    }

    private searchCliente(rut: any) {

        const rutCliente = rut.id;

        if (rutCliente == "" || rutCliente == undefined) {

            ClienteStore.getAllClientes()
                .then((response: any) => {

                    this.setState({ dataSource: response.data.cliente });
                })
                .catch((error: any) => {

                });
        }
        else {
            this.getCliente(rutCliente);
        }

    }

    getCliente(rutCliente: any) {

        ClienteStore.getCliente(rutCliente)
            .then((response: any) => {
                if (!response.data.success) {
                    notification.info({
                        message: '¡Advertencia!',
                        description: response.data.message
                    })
                }

                this.setState({ dataSource: response.data.cliente });
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    handleSearch(e: any) {

        if (e.type == "click") {
            this.setState({ dataSource: '' });
        }

    }

    editCliente(cliente: any) {

        ClienteStore.editCliente(cliente.KOEN, cliente)
            .then((response: any) => {

            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    showFormulario(data: any) {

        debugger;
        if (data.KOEN != undefined) {
            
            const comuna = this.state.dataComuna.find((item: any) => item.KOCM.includes(data.CMEN));

            this.setState({ comunaSelected: comuna.NOKOCM });

            this.setState({ showFormEdit: true });
            this.loadRegion(data.CIEN);
            this.setState({ editedCliente: data });
        }
        else {
            this.setState({ showForm: true });

        }

    }

    saveCliente = (body: any) => {


        if (this.state.editedCliente != null || this.state.editedCliente != undefined) {
            
            this.loadRegion(body.CIEN);
            body.KOEN = body.KOEN.trim();
            body.CMEN = body.CMEN.trim();

            const newBody = {
                SUEN: this.state.editedCliente.SUEN,
                ...body
            }

            ClienteStore.editCliente(body.KOEN, newBody)
                .then((response: any) => {
                    this.setState({ showFormEdit: false });
                    notification.success({
                        message: 'Actualización existosa',
                        description: response.data.message
                    });

                    this.getCliente(body.KOEN);
                    // // Modificando el Arrego de la tabla
                    // const newArray = this.state.dataSource.map((item: any) => {

                    //     if (item.KOEN.includes(bodyEdited.KOEN) && item.SUEN == this.state.editedCliente.SUEN) {
                    //         return bodyEdited;
                    //     }
                    //     else {
                    //         return item;
                    //     }
                    // });

                    // this.setState({
                    //     dataSource: newArray
                    // });

                })
                .catch((error: any) => {
                    console.log(error);
                })
        }
        else {
            let rut = body.KOEN.replace("-", "");
            rut = rut.substring(0, rut.length - 1);

            body.KOEN = rut;
            body.CMEN = body.CMEN.trim();

            ClienteStore.addCliente(body)
                .then((response: any) => {
                    this.setState({ showForm: false });
                    notification.success({
                        message: 'Registro existoso',
                        description: response.data.message
                    });
                    this.resetForm.resetFields();
                })
                .catch((error: any) => {
                    console.log(error);
                })
        }



    }

    onClose() {
        this.setState({ showForm: false });
        this.setState({ showFormEdit: false });
        this.setState({ editedCliente: [] });
        this.resetForm.resetFields();
    }

    validateRut = (rule: any, value: any, callback: any) => {

        if (value && !RegularExpression.Rut(value)) {
            callback('Formato de Rut no válido. Ejemplo: 40556336-5');
        }
        else {
            if (Utilidades.ValidateRut(value)) {
                callback();
            } else {
                callback('Rut ingresado no es válido');
            }
        }
    }

    public render() {

        const columns = [
            { title: 'Cod Sucursal', dataIndex: 'SUEN', key: 'SUEN' },
            { title: 'Rut cliente', dataIndex: 'RTEN', key: 'RTEN' },
            { title: 'Nombre cliente', dataIndex: 'NOKOEN', key: 'NOKOEN' },
            { title: 'Dirección', dataIndex: 'DIEN', key: 'DIEN' },
            {
                title: 'Acciones', key: 'action', render: (text: any, record: any) => (<Space size="middle">
                    <Button type="link" onClick={() => this.showFormulario(record)}>Editar</Button>
                </Space>)
            },

        ];


        return (
            <div>
                <div style={{ backgroundColor: ' #f1efee ' }}>
                    <div>
                        <PageHeader title="Clientes" />
                    </div>
                    <Form onFinish={this.searchCliente}>
                        <Row>
                            <Form.Item name="id">

                                <Input style={{ marginLeft: '20px', width: '100%' }} type="text" allowClear onChange={this.handleSearch} placeholder="Ingresar rut cliente" />
                            </Form.Item>
                            <Form.Item>
                                <Button style={{ marginLeft: '30px' }} type="primary" htmlType="submit" shape="round">Buscar</Button>
                            </Form.Item>
                            <Button type="primary" shape="round" icon={<PlusOutlined />} style={{ float: 'right', margin: '0 20px 0 auto' }} onClick={this.showFormulario.bind(this)}>Agregar</Button>
                        </Row>

                    </Form>

                </div>

                <div style={{ backgroundColor: ' #f1efee ', marginTop: '50px' }}>
                    <Table columns={columns} dataSource={this.state.dataSource} />
                </div>
                <Drawer
                    title="Ingresar nuevo cliente"
                    width={720}
                    visible={this.state.showForm}
                    bodyStyle={{ paddingBottom: 80 }}
                    onClose={this.onClose.bind(this)}
                >
                    <Form layout="vertical" hideRequiredMark onFinish={this.saveCliente} ref={r => this.resetForm = r}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="NOKOEN"
                                    label="Nombre"
                                    rules={[{ required: true, message: 'Por favor ingresar nombre' }]}
                                >
                                    <Input placeholder="Ingresar nombre" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="KOEN"
                                    label="Rut"
                                    rules={[{ required: true, message: 'Por favor ingresar rut sin puntos y con guión', validator: this.validateRut }]}

                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Ingresar rut"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="GIEN"
                                    label="Giro"
                                    rules={[{ required: true, message: 'Por favor ingresar giro' }]}
                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Ingresar giro"
                                    />
                                    {/* <Select placeholder="Please select an owner">
                                        <Option value="xiao">Xiaoxiao Fu</Option>
                                        <Option value="mao">Maomao Zhou</Option>
                                    </Select> */}
                                </Form.Item>
                            </Col>


                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="CIEN"
                                    label="Region"
                                    rules={[{ required: true, message: 'Por favor seleccionar región' }]}
                                >
                                    <Select placeholder="Seleccionar región">
                                        <Option value="RM">Región Metropolitana</Option>
                                        <Option value="15">Arica y Parinacota</Option>
                                        <Option value="1">Tarapacá</Option>
                                        <Option value="2">Antofagasta</Option>
                                        <Option value="3">Atacama</Option>
                                        <Option value="4">Coquimbo</Option>
                                        <Option value="5">Valparaiso</Option>
                                        <Option value="6">Ohiggins</Option>
                                        <Option value="7">Maule</Option>
                                        <Option value="16">Ñuble</Option>
                                        <Option value="8">Biobío</Option>
                                        <Option value="9">La Araucanía</Option>
                                        <Option value="14">Los Ríos</Option>
                                        <Option value="10">Los Lagos</Option>
                                        <Option value="11">Aysén del General Carlos Ibañez del Campo</Option>
                                        <Option value="12">Magallanes y La Antártica Chilena</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="CMEN"
                                    label="Comuna"
                                    rules={[{ required: true, message: 'Por favor seleccionar comuna' }]}
                                >
                                    <Select showSearch placeholder="Seleccionar comuna" filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                        {this.state.dataComuna && this.state.dataComuna.map((item: any, index: any) => <Option value={item.NOKOCM}>{item.NOKOCM}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="DIEN"
                                    label="Direccion"
                                    rules={[{ required: true, message: 'Por favor ingresar dirección' }]}
                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Ingresar dirección"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="FOEN"
                                    label="Teléfono"
                                    rules={[{ required: true, message: 'Por favor ingresar teléfono' }]}
                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Ingresar teléfono"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="CPEN"
                                    label="Tipo de pago"

                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Ingresar tipo de pago"
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col span={12}>
                                <Form.Item
                                    name="SUEN"
                                    label="Sucursal"
                                    rules={[{ required: true, message: 'Por favor seleccionar sucursal' }]}
                                >
                                    <Select placeholder="Seleccionar sucursal">
                                    <Option value="CMA">Casa Matriz</Option>
                                    <Option value="EFE">Estación Central</Option>
                                    </Select>
                                </Form.Item>
                            </Col> */}
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="OBEN"
                                    label="Observaciones"

                                >
                                    <Input.TextArea rows={4} placeholder="Ingresar observación" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Button style={{ marginRight: '10px' }} type="primary" htmlType="submit" shape="round">Guardar</Button>
                                <Button type="primary" danger shape="round" onClick={this.onClose.bind(this)}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>


                <Drawer
                    title="Editar cliente"
                    width={720}
                    visible={this.state.showFormEdit}
                    bodyStyle={{ paddingBottom: 80 }}
                    onClose={this.onClose.bind(this)}
                    destroyOnClose
                >
                    <Form layout="vertical" hideRequiredMark onFinish={this.saveCliente} ref={r => this.resetForm = r}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="NOKOEN"
                                    label="Nombre"
                                    rules={[{ required: true, message: 'Por favor ingresar nombre' }]}
                                    initialValue={this.state.editedCliente && this.state.editedCliente.NOKOEN}
                                >
                                    <Input placeholder="Ingresar nombre" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="KOEN"
                                    label="Rut"
                                    // rules={[{ required: true, message: 'Por favor ingresar rut sin puntos y con guión',validator:this.validateRut }]}
                                    initialValue={this.state.editedCliente && this.state.editedCliente.KOEN}
                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Ingresar rut"
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="GIEN"
                                    label="Giro"
                                    rules={[{ required: true, message: 'Por favor ingresar giro' }]}
                                    initialValue={this.state.editedCliente && this.state.editedCliente.GIEN}
                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Ingresar giro"
                                    />
                                    {/* <Select placeholder="Please select an owner">
                                        <Option value="xiao">Xiaoxiao Fu</Option>
                                        <Option value="mao">Maomao Zhou</Option>
                                    </Select> */}
                                </Form.Item>
                            </Col>


                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="CIEN"
                                    label="Region"
                                    rules={[{ required: true, message: 'Por favor seleccionar región' }]}
                                    initialValue={this.state.editedCliente && this.state.editedCliente.CIEN ? `${this.state.editedCliente.CIEN}` : undefined}
                                >
                                    <Select placeholder="Seleccionar región">
                                        <Option value="RM">Región Metropolitana</Option>
                                        <Option value="15">Arica y Parinacota</Option>
                                        <Option value="1">Tarapacá</Option>
                                        <Option value="2">Antofagasta</Option>
                                        <Option value="3">Atacama</Option>
                                        <Option value="4">Coquimbo</Option>
                                        <Option value="5">Valparaiso</Option>
                                        <Option value="6">Ohiggins</Option>
                                        <Option value="7">Maule</Option>
                                        <Option value="16">Ñuble</Option>
                                        <Option value="8">Biobío</Option>
                                        <Option value="9">La Araucanía</Option>
                                        <Option value="14">Los Ríos</Option>
                                        <Option value="10">Los Lagos</Option>
                                        <Option value="11">Aysén del General Carlos Ibañez del Campo</Option>
                                        <Option value="12">Magallanes y La Antártica Chilena</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="CMEN"
                                    label="Comuna"
                                    rules={[{ required: true, message: 'Por favor seleccionar comuna' }]}
                                    initialValue={this.state.editedCliente && this.state.editedCliente.CMEN ? `${this.state.editedCliente.CMEN}` : undefined}
                                >
                                    <Select showSearch placeholder="Seleccionar comuna" filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                        {this.state.dataComuna && this.state.dataComuna.map((item: any, index: any) => <Option key={item.KOCM} value={item.KOCM}>{item.NOKOCM}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="DIEN"
                                    label="Direccion"
                                    rules={[{ required: true, message: 'Por favor ingresar dirección' }]}
                                    initialValue={this.state.editedCliente && this.state.editedCliente.DIEN}
                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Ingresar dirección"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="FOEN"
                                    label="Teléfono"
                                    rules={[{ required: true, message: 'Por favor ingresar teléfono' }]}
                                    initialValue={this.state.editedCliente && this.state.editedCliente.FOEN}
                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Ingresar teléfono"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="CPEN"
                                    label="Tipo de pago"
                                    initialValue={this.state.editedCliente && this.state.editedCliente.CPEN}
                                >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Ingresar tipo de pago"
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col span={12}>
                                <Form.Item
                                    name="SUEN"
                                    label="Sucursal"
                                    rules={[{ required: true, message: 'Por favor seleccionar sucursal' }]}
                                >
                                    <Select placeholder="Seleccionar sucursal">
                                    <Option value="CMA">Casa Matriz</Option>
                                    <Option value="EFE">Estación Central</Option>
                                    </Select>
                                </Form.Item>
                            </Col> */}
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="OBEN"
                                    label="Observaciones"
                                    initialValue={this.state.editedCliente && this.state.editedCliente.OBEN}
                                >
                                    <Input.TextArea rows={4} placeholder="Ingresar observación" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Button style={{ marginRight: '10px' }} type="primary" htmlType="submit" shape="round">Guardar</Button>
                                <Button type="primary" danger shape="round" onClick={this.onClose.bind(this)}>Cancelar</Button>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            </div>

        )
    }

}
