import { axios } from './ini';

export class LoginStore{

    public static getUserSessionData(body:any){
        return axios.post('login/authenticate',body)
        .then((response:any) => {
            return response;
        });
    }
}

export default LoginStore;

