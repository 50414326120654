import React from 'react'
import { Table, Form, Col, Row, PageHeader, Input, Button, Space } from 'antd';
import PedidoStore from '../../Store/pedidoStore';
import * as moment from 'moment';
import { Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';

interface IPedidoStates{
    dataPedidos:any;
    data:any;
}

export default class MantenedorPedidos extends React.Component<{}, IPedidoStates>{

    constructor(props: any) {
        super(props);
        this.state={
            dataPedidos:[],
            data:[]
        }

        this.searchPedido = this.searchPedido.bind(this);
        this.showDetallePedido = this.showDetallePedido.bind(this);
    }

    
    searchPedido(rutCliente: any) {
        
        const {rut} = rutCliente;
        PedidoStore.getPedidosCliente(rut)
            .then((response: any) => {
                this.setState({dataPedidos:response.data.cliente});
            })
            .catch((error) => {
                console.log(error);
            });
        
    }

    showDetallePedido(codigo:any){
        
        return <Redirect to="/detalle-pedido"/>;
    }

    public render() {

        const columns = [
            { title: 'Cliente', dataIndex: 'ENDO', key: 'ENDO' },
            { title: 'N° documento', dataIndex: 'NUDO', key: 'NUDO' },
            { title: 'Fecha emisión', dataIndex: 'FEEMDO', key: 'FEEMDO' },
            { title: 'Vendedor', dataIndex: 'NOKOFU', key: 'NOKOFU' },
            
            {
                title: 'Acciones', key: 'action', render: (text: any, record: any) => (<Space size="middle">
                    <NavLink to={{pathname:"/detalle-pedido", state:record }}>Ver detalle</NavLink>
                    {/* <Button type="link" onClick={() => this.showDetallePedido(record)}>Ver detalle</Button> */}
                </Space>)
            },
        ];
        return (
            <div>
            <div style={{ backgroundColor: ' #f1efee ' }}>
                <Row gutter={24}>
                    <Col>
                        <PageHeader title="Pedidos" />
                    </Col>
                    <Col span={20}>
                        {/* <Button style={{ float: 'right', marginTop: '50px' }} type="primary" shape="round" className="btnAgregar" onClick={this.showModalAddUser}>Nuevo usuario</Button> */}
                    </Col>

                </Row>
                <Form onFinish={this.searchPedido} >
                    <Row>
                        <Form.Item name="rut">
                            <Input style={{ marginLeft: '20px', width: '100%' }} type="text" allowClear placeholder="Ingresar rut" />
                        </Form.Item>
                        <Form.Item>
                            <Button style={{ marginLeft: '30px' }} type="primary" htmlType="submit" shape="round">Buscar</Button>
                        </Form.Item>
                    </Row>
                </Form>
                </div>
                <div style={{backgroundColor:' #f1efee ',marginTop:'50px'}}>
                <Table columns={columns} dataSource={this.state.dataPedidos}>
                </Table>
                </div>
            </div>
            

        )
    }

}
