import { Layout, Menu, Breadcrumb, Button } from 'antd'
import { BrowserRouter, HashRouter, Route, Router, Switch, Redirect } from 'react-router-dom';
import React from 'react'
import '../../css/home/home.css';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  FileDoneOutlined,
  ShoppingOutlined,
  HomeOutlined
} from '@ant-design/icons';
import App from '../../App';
import { NavLink } from "react-router-dom";
import MantenedorUsuarios from '../Usuarios/MantenedorUsuarios';
import FormLogin from '../Login/FormLogin';
import MantenedorClientes from '../Clientes/MantenedorClientes';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;



interface IHomeState {
  collapsed: boolean;
  loggedIn: boolean;
  current: string;
}

interface IHomeProps{
data:any;
}

let routes={};
export default class Home extends React.Component<IHomeProps, IHomeState>{

  constructor(props: any) {
    super(props);
    this.state = {
      collapsed: false,
      loggedIn: false,
      current: ''
    }
  }

  componentDidMount() {
    const isLogged = (sessionStorage.getItem("usuario") !== null) ? true : false;
    //alert(isLogged)
    this.setState({
      loggedIn: isLogged
    })
  }

  public handleHeaderMenuClick = (option: string) => {
    this.setState({ current: option });
  }

  onCollapse = (collapsed: any) => {
    
    this.setState({ collapsed });
  };

  logout() {
    sessionStorage.removeItem("usuario");
    <NavLink to="/login"/>
  }
  
  public loadRoutes(route:any){

   

    return route;
  }
  render() {
    //const { collapsed } = this.state;
    if (!this.state.loggedIn){
      return <FormLogin {...this.props.data} />;
    }
    return (

      <div></div>
      // <Layout style={{ minHeight: '100vh' }}>
      //   <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
      //     <div className="logo" />



      //     <Menu theme="dark" mode="inline">
      //       <Menu.Item key="home" icon={<HomeOutlined />} onClick={this.loadRoutes}><span>Home</span>

      //       </Menu.Item>
      //       <Menu.Item key="usuarios" icon={<UserOutlined />} onClick={this.loadRoutes}><span>Usuarios</span>

      //       </Menu.Item>
      //       <Menu.Item key="clientes" icon={<DesktopOutlined />} onClick={this.loadRoutes}><span>Clientes</span>

      //       </Menu.Item>
      //       <Menu.Item key="4" icon={<ShoppingOutlined />}><NavLink to="/productos"><span>Productos</span></NavLink>

      //       </Menu.Item>
      //       {/* <SubMenu key="sub1" icon={<UserOutlined />} title="User">
      //             <Menu.Item key="3">Tom</Menu.Item>
      //             <Menu.Item key="4">Bill</Menu.Item>
      //             <Menu.Item key="5">Alex</Menu.Item>
      //           </SubMenu>
      //           <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
      //             <Menu.Item key="6">Team 1</Menu.Item>
      //             <Menu.Item key="8">Team 2</Menu.Item>
      //           </SubMenu> */}
      //       <Menu.Item key="5" icon={<FileDoneOutlined />}><NavLink to="/notasVenta"><span>Notas de venta</span></NavLink>

      //       </Menu.Item>
      //       <Menu.Item key="6" icon={<ShoppingCartOutlined />}><NavLink to="/ventas"><span>Ventas</span></NavLink>
              
      //       </Menu.Item>
      //     </Menu>
      //   </Sider>
      //   <Layout className="site-layout" style={{ background: '#ffffff' }}>
      //     <Header className="site-layout-background" style={{ padding: 0 }}><span style={{ float: 'right', paddingRight: '10px' }}><Button type="primary" icon={<LogoutOutlined title="Cerra sesión" onClick={this.logout} />}></Button></span></Header>

      //     <Content style={{ margin: '0 16px' }}>
      //       <App {...this.props.data}/>
      //       {/* <Breadcrumb style={{ margin: '16px 0' }}>
      //             <Breadcrumb.Item>User</Breadcrumb.Item>
      //             <Breadcrumb.Item>Bill</Breadcrumb.Item>
      //           </Breadcrumb>
      //           <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
      //             Bill is a cat.
      //           </div> */}

      //     </Content>
      //     <Footer style={{ textAlign: 'center' }}>Created by PowerData</Footer>
      //   </Layout>
      // </Layout>
    )
  }


}
