import { Button, Form, Input, notification, Select, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import React from 'react';
import img from '../../assets/img/usuario-login.png';
import bkg from '../../assets/img/01.jpg';
import '../../css/login/login.css';
import 'antd/dist/antd.css';

import LoginStore from '../../Store/loginStore';
import { HEADERS } from '../../config';
import { Redirect, useHistory } from 'react-router';
import FormItem from 'antd/lib/form/FormItem';
import UsuarioStore from '../../Store/usuarioStore';


interface ILoginProps {
    form: any;
    //onAuthorizeAction?: any;
    data: any;
}

interface ILoginStates {

    username: string;
    password: string;
    isLogged: boolean;
    dataModalidades: any;
    modalidadUsuario:any;
    sucursalUsuario:any;
}

const { Option } = Select;


export default class FormLogin extends React.Component<ILoginProps, ILoginStates>{

    private refCondiciones:any;
    constructor(props: any) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isLogged: false,
            dataModalidades: [],
            modalidadUsuario:'',
            sucursalUsuario:''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public componentDidMount() {

        UsuarioStore.getModalidades()
            .then((response: any) => {
                this.setState({ dataModalidades: response.data })
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    public handleSubmit = (e: any) => {
        
        if(e.modalidad==undefined || e.modalidad == ""){
            e.modalidad = this.state.modalidadUsuario;
        }
        const body = {
            usuario: e.username,
            password: e.password
        }
        JSON.stringify(body)
        LoginStore.getUserSessionData(body)
            .then((response: any) => {


                sessionStorage.setItem('usuario', response.data.nombreUsuario);
                sessionStorage.setItem('modalidad', e.modalidad);
                sessionStorage.setItem('sucursal', this.state.sucursalUsuario);
                notification.success({
                    message: 'Ingreso exitoso',
                    description: `Bienvenido(a) usuario ${response.data.nombreUsuario}`
                });

                this.setState({ isLogged: true });
                //return (<Redirect to={{ pathname: '/home' }} />)
                window.location.pathname = "/home";
            })
            .catch((err: any) => {
                notification.error({
                    message: 'Ha ocurrido un error',
                    description: 'No ha sido posible iniciar sesión. Por favor verificar credenciales'
                })
            })

    }

    onChangeUsuario(e:any){

        if(e.target.value!= null || e.target.value != ""){
            if(e.target.value.toString().length==3){
                
                UsuarioStore.getModalidadPorUsuario(e.target.value)
                .then((response:any)=>{
                    
                    if(response==undefined){
                        this.setState({modalidadUsuario:''})
                        return notification.warning({
                            message:'Advertencia',
                            description:'Usuario no existe en el sistema'
                        });
                    }
                    this.setState({modalidadUsuario:response.data[0].MODALIDAD, sucursalUsuario:response.data[0].ESUCURSAL});
                })
                .catch((error:any)=>{
                    console.log(error);
                })  
            }
        }
        
    }

    onChangeModalidad(e:any){
        this.setState({modalidadUsuario:e});
    }

    render() {
        if (this.state.isLogged) {

            return (<Redirect to={{ pathname: '/home' }} />)
        }
        return (
            <div className="bgLogin">
                <Form name="login"
                    className="login-form" onFinish={this.handleSubmit}>
                    <img src={img} style={{ width: "150px", padding: "10px" }} />
                    <Form.Item name="username"
                        rules={[{ required: true, message: 'Por favor ingresar usuario' }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Usuario" value={this.state.username} onChange={this.onChangeUsuario.bind(this)} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Por favor ingresar contraseña' }]}>
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Contraseña"
                            value={this.state.password}
                        />
                    </Form.Item>
                    <Form.Item name="modalidad"
                        rules={[{required:false, message:'Por favor seleccionar modalidad'}]} valuePropName={this.state.modalidadUsuario}>
                        <Select placeholder="Seleccionar modalidad" value={this.state.modalidadUsuario} ref={r => this.refCondiciones = r} onChange={this.onChangeModalidad.bind(this)}>
                            {this.state.dataModalidades && this.state.dataModalidades.map((item: any) => <Option  value={item.MODALIDAD}  key={item.MODALIDAD}>{item.MODALIDAD}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Ingresar
                        </Button>
                    </Form.Item>
                </Form>

            </div>


        )
    }

}

