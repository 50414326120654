import { Input, Form, Row, Col, Select, Table, Image, DatePicker, Button, PageHeader, Collapse, notification, FormProps, List, Modal, Radio, Divider, Card, Avatar, Tabs, Space, Descriptions, Typography, Popconfirm, Tooltip, message, Alert } from 'antd';
import img from '../../assets/img/producto-sin-imagen.png';
import React from 'react'
import ProductoStore from '../../Store/productoStore';
import ClienteStore from '../../Store/clienteStore';
import { ExclamationCircleOutlined, InfoCircleOutlined, ShoppingOutlined, DeleteOutlined, ContainerOutlined, FolderOpenOutlined, SaveOutlined } from '@ant-design/icons';
import { useValue } from '../../hooks/useValue';
import FormItem from 'antd/lib/form/FormItem';
import { axios } from '../../Store/ini';
import PedidoStore from '../../Store/pedidoStore';
import { Link, NavLink } from 'react-router-dom';
import '../../css/ventas/venta.css';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { ColumnType } from 'antd/lib/list';
import { error } from 'console';

interface IProductoStates {
    dataSource: any;
    dataProducto: any;
    dataCliente: any;
    dataPrecio: any;
    dataDirecciones: any;
    dataStockSucursal: any;
    dataProductosOfertas: any;
    rutCliente: any;
    nombreCliente: any;
    dataTableProducto: any;
    nombreProducto: any;
    currentDate: any;
    disabledCantidad: any;
    disabledCodigo: any;
    disabledDescripcion: any;
    valueCodigo: any;
    showModalDireccion: any;
    valueRadio: any;
    valueRadioDocumento: any;
    urlImage: string;
    showImagen: any;
    showModalProductos: any;
    totalNotaVenta: any;
    showModalDocumento: any;
    showModalAutorizacion: any;
    showCategorias: any;
    selectedRowKeys: any;
    urlImagenProducto: any;
    cantidadProductoCotizacion: any;
    notaVentaActual: string;
    tipoDocumento: string;
    dataDocumentosCliente: any;
    showModalDocumentosCliente: boolean;
    modalidad: any;
    focusPago: boolean;
    dataTableLoadNota: any;
    dataClientePorNombre: any;
    showModalBusquedaCliente: any;
    cantidadEmbalaje: any;
    totalUnidades: any;
    showInfoProducto: boolean;
    dataProductos: any;
    dataPedidosPendientes: any;
    showModalPedidosPendientes: any;
    sucursalUsuario: any;
    showModalGuardarDocumento: boolean;
    dataPendientesUsuario: any;
    showModalPendientesUsuario: boolean;
    condicionesPago: string;
    observacionesCliente: string;

}

const { TextArea } = Input;
const { Option } = Select;
const { Meta } = Card;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { confirm } = Modal;


export default class MantenedorVentas extends React.Component<{}, IProductoStates>{

    private resetForm: any;
    private refCondiciones: any;
    private refCantidad: any;
    private refCantidadTabla: any;
    private refCodigo: any;
    private refRut: any;
    private selected: any;
    private bodyProducto: any;


    constructor(props: any) {
        super(props);
        this.state = {
            dataSource: [],
            dataCliente: [],
            dataTableProducto: [],
            dataPrecio: [],
            dataDirecciones: [],
            dataStockSucursal: [],
            dataProductosOfertas: [],
            rutCliente: '',
            nombreCliente: '',
            nombreProducto: '',
            currentDate: '',
            disabledCantidad: true,
            disabledCodigo: true,
            valueCodigo: '',
            showModalDireccion: false,
            valueRadio: false,
            urlImage: img,
            showImagen: false,
            showModalProductos: false,
            disabledDescripcion: true,
            totalNotaVenta: 0,
            valueRadioDocumento: false,
            showModalDocumento: false,
            showModalAutorizacion: false,
            showCategorias: true,
            selectedRowKeys: [],
            dataProducto: [],
            urlImagenProducto: '',
            cantidadProductoCotizacion: 0,
            notaVentaActual: '',
            tipoDocumento: 'NVV',
            dataDocumentosCliente: [],
            showModalDocumentosCliente: false,
            modalidad: '',
            focusPago: false,
            dataTableLoadNota: [],
            dataClientePorNombre: [],
            showModalBusquedaCliente: false,
            cantidadEmbalaje: '',
            totalUnidades: '',
            showInfoProducto: false,
            dataProductos: [],
            dataPedidosPendientes: [],
            showModalPedidosPendientes: false,
            sucursalUsuario: '',
            showModalGuardarDocumento: false,
            dataPendientesUsuario: [],
            showModalPendientesUsuario: false,
            condicionesPago: '',
            observacionesCliente: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.addProducto = this.addProducto.bind(this);
        this.handleCodigo = this.handleCodigo.bind(this);
        this.searchCliente = this.searchCliente.bind(this);
        this.handleRut = this.handleRut.bind(this);
        this.confirmarDireccion = this.confirmarDireccion.bind(this);
        this.cancelarDireccion = this.cancelarDireccion.bind(this);
        this.loadImage = this.loadImage.bind(this);
        this.showModalProductos = this.showModalProductos.bind(this);
        this.cancelModalProductos = this.cancelModalProductos.bind(this);
        this.handleDescripcion = this.handleDescripcion.bind(this);
        this.confirmSaveNotaDeVenta = this.confirmSaveNotaDeVenta.bind(this);
        this.calcularTotal = this.calcularTotal.bind(this);
        this.onChangeDocumento = this.onChangeDocumento.bind(this);
        this.saveTipoDocumento = this.saveTipoDocumento.bind(this);
        this.generarNotaVenta = this.generarNotaVenta.bind(this);
        this.cancelTipoDocumento = this.cancelTipoDocumento.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
        this.showProductos = this.showProductos.bind(this);
        this.searchProducto = this.searchProducto.bind(this);
        this.handleProducto = this.handleProducto.bind(this);
        this.searchNumeroPedido = this.searchNumeroPedido.bind(this);
        this.searchDocumento = this.searchDocumento.bind(this);
        this.limpiar = this.limpiar.bind(this);
    }

    componentDidMount() {

        this.setState({ modalidad: sessionStorage.getItem('modalidad'), sucursalUsuario: sessionStorage.getItem('sucursal') });
        const today = new Date();
        this.setState({ currentDate: (today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear()) })
        ProductoStore.GetAllProductos()
            .then((response: any) => {

                this.setState({ dataSource: response.data.listaProducto });
                this.setState({ dataProducto: response.data.listaProducto });
                this.setState({ dataProductos: response.data.productos });
                this.setState({ dataProductosOfertas: response.data.listaProducto.filter((item: any) => item.RUPR == "OFE") });

            })
            .catch((error: any) => {
                notification.error({
                    message: 'Error!',
                    description: 'Por favor intentelo nuevamente'
                })
            });

        this.loadNotaVentaActual();

        // ClienteStore.getAllClientes()
        //     .then((response: any) => {
        //         this.setState({ dataCliente: response.data.cliente });

        //     })
        //     .catch((error: any) => {
        //         console.log(error);
        //     })
    }

    loadNotaVentaActual() {
        PedidoStore.getNotaVentaActual()
            .then((response: any) => {

                this.setState({ notaVentaActual: response.data });

            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    componentDidUpdate() {
        // setTimeout(() => {
        //     message.info('autoguardado')
        // }, 1500);
    }

    searchCliente(rut: any) {

        if (rut.target.value == undefined || rut.target.value == "") {
            this.setState({ dataCliente: [], rutCliente: '', condicionesPago: '', observacionesCliente: '' })
            this.setState({ showModalBusquedaCliente: true });
            //this.setState({ dataCliente: '' });
            // return notification.warning({
            //     message: 'Atención!',
            //     description: 'Por favor ingresar rut de cliente'
            // });
        }
        else {
            this.setState({ rutCliente: rut.target.value });
            ClienteStore.getCliente(this.state.rutCliente)
                .then((response: any) => {

                    if (response.data.success == false) {
                        this.setState({ dataCliente: '' });
                        return notification.info({
                            message: 'Atención!',
                            description: response.data.message
                        })
                    }

                    if (response.data.cliente.length > 1) {
                        this.setState({ showModalDireccion: true });
                        this.setState({ dataDirecciones: response.data.cliente });
                    }


                    this.setState({ dataCliente: response.data.cliente[0] });
                    this.setState({ disabledCodigo: false });
                    this.setState({ disabledDescripcion: false });
                    this.setState({ condicionesPago: response.data.cliente[0].CPEN, observacionesCliente: response.data.cliente[0].OBEN })


                    this.refCondiciones.focus();
                    this.refCondiciones.setSelectionRange(0, 0);

                })
                .catch((error: any) => {

                });

            this.loadPedidosPendientes(this.state.rutCliente)
        }
    }

    cancelModalCliente() {
        this.setState({ showModalBusquedaCliente: false, dataClientePorNombre: [] });
    }

    loadPedidosPendientes(rut: any) {
        PedidoStore.getPedidosPendientes(rut)
            .then((response: any) => {
                if (response.data.length > 0) {
                    this.setState({ dataPedidosPendientes: response.data, showModalPedidosPendientes: true });
                }

            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    searchClienteModal(rut: any) {
        if (rut.target.value == "") {
            return notification.warning({
                message: 'Atención!',
                description: 'Por favor ingresar rut de cliente'
            });
            // ClienteStore.getAllClientes()
            // .then((response:any)=>{
            //     this.setState({ dataClientePorNombre: response.data.cliente });
            // })
        }
        else {
            ClienteStore.getCliente(rut.target.value)
                .then((response: any) => {
                    this.setState({ dataClientePorNombre: response.data.cliente });
                })
                .catch((error: any) => {

                });
        }

    }

    searchNumeroPedido(numeroPedido: any) {

        const numPedido = numeroPedido.target.value;
        const tipoDoc = this.state.tipoDocumento;

        if (numeroPedido.target.value == "") {
            return notification.info({
                message: 'Advertencia',
                description: 'Debe ingresar un número de pedido'
            });
        }


        PedidoStore.getPedidoPorNumero(numPedido, tipoDoc)
            .then((response: any) => {

                this.setState({ dataDocumentosCliente: response.data.tipoDocumento });

                // const cliente = response.data.cliente.map((item: any) => item.c);
                // const temp = this.state.dataDocumentosCliente.map((item: any) => item.TIDO);
                this.setState({ tipoDocumento: response.data.tipoDocumento.TIDO });
                this.setState({ dataCliente: response.data.cliente.c });
                this.setState({rutCliente:response.data.cliente.c.KOEN, condicionesPago:response.data.cliente.c.OBEN, observacionesCliente:response.data.cliente.c.CPEN})

                // { title: 'COD', dataIndex: 'KOPR', key: 'KOPR' },
                // { title: 'Detalle', dataIndex: 'NOKOPR', key: 'NOKOPR' },
                // { title: 'Cantidad', dataIndex: 'STOCNV1', key: 'STOCNV1' },
                // { title: 'Precio', dataIndex: 'precio', key: 'precio' },
                // { title: 'Dscto', dataIndex: 'descuento', key: 'descuento' },
                // { title: '', dataIndex: 'valorDescuento', key: 'valorDescuento' },
                // { title: 'Total', dataIndex: 'total', key: 'total' },

                this.setState({ dataTableProducto: response.data.pedido });

                if (response.data.tipoDocumento.length > 1) {
                    this.setState({ showModalDocumentosCliente: true });
                }

                this.calcularTotal();

            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    searchDocumento(tipo: any) {

        const aux = tipo.target.value.toUpperCase();
        if (aux == "") {
            return notification.info({
                message: 'Advertencia',
                description: 'Debe ingresar un tipo de documento'
            })
        }
        PedidoStore.getNumeroDocumento(aux)
            .then((response: any) => {

                this.setState({ notaVentaActual: response.data, dataTableProducto: [], dataCliente: [] })
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    handleRut(e: any) {
        if (e.type == "click") {
            this.setState({ dataCliente: '' });
            this.setState({ dataTableProducto: [] });
            this.setState({ totalNotaVenta: 0 });
            this.setState({ disabledCodigo: true });
            this.setState({ disabledDescripcion: true });
            this.setState({ disabledCantidad: true });
            this.resetForm.resetFields();
        }
    }

    handleChange(event: any) {
        this.setState({ nombreCliente: '' });
        const aux = this.state.dataCliente.find((item: any) => item.RTEN == event);
        this.setState({ nombreCliente: aux });


    }

    handleCodigo(e: any) {


        const aux = this.state.dataProductos.find((item: any) => item.KOPR == e);
        this.setState({ nombreProducto: aux });

        if (aux != undefined) {
            this.loadImage(e.trim());
            this.setState({ disabledCantidad: false, showInfoProducto: true });
            this.setState({ showImagen: true });

            this.refCantidad.focus();
            this.refCantidad.setSelectionRange(0, 0);

        }
        else {
            this.resetForm.resetFields();
            this.setState({ disabledCantidad: true, showInfoProducto: false, showImagen: false, urlImage: img });
        }

        this.loadStockSucursal(e);

    }

    handleDescripcion(e: any) {

        const aux = this.state.dataSource.find((item: any) => item.KOPR == e);
        this.setState({ nombreProducto: aux });

        if (aux != undefined) {
            this.loadImage(e.trim());
            this.setState({ disabledCantidad: false, showInfoProducto: true, showImagen: true });
        }
        else {
            this.resetForm.resetFields();
            this.setState({ disabledCantidad: true, showInfoProducto: false, showImagen: false, urlImage: img });
        }
        this.loadStockSucursal(e);
    }

    loadImage(imagen: any) {

        ProductoStore.GetImagenProducto(imagen.trim())
            .then((response: any) => {
                if (response.data.success == false) {
                    this.setState({ urlImage: img });
                }
                else {
                    this.setState({ urlImage: response.data.response.m_Uri });
                }

            })
            .catch((error: any) => {

            })

    }

    addProducto = async (body: any) => {

        if (this.state.nombreProducto.KOPR == undefined) {
            return notification.info({
                message: 'Advertencia',
                description: 'Debe ingresar código de producto'
            });
        }


        if (body.CAPRCO1 == undefined || body.CAPRCO1 == "0" || body.CAPRCO1 == "") {
            return notification.info({
                message: 'Advertencia',
                description: 'Debe ingresar una cantidad superior a 0'
            });
        }


        const tipoDoc = this.state.tipoDocumento.toUpperCase();
        if (tipoDoc == "COV") {

            let total = 0;
            const sucursal = sessionStorage.getItem("sucursal");
            const aux = this.state.dataStockSucursal.find((item: any) => item.KOSU == sucursal);

            const cantidad = parseInt(this.refCantidad.props.value);


            if (aux == undefined) {
                total = 0;
            }
            else {
                const comprometido = parseInt(aux.STOCNV1);
                const stock = parseInt(aux.STFI1);

                total = (stock - comprometido);
            }


            if (cantidad > total) {
                Modal.info({
                    title: 'Información',
                    content: (
                        <div>
                            <p>La cantidad es superior a la que existe en bodega</p>
                        </div>
                    ),
                    onOk() { },
                    okText: 'Aceptar'
                })
            }

        }




        const lista = this.state.dataCliente.LVEN;
        const codigo = this.state.nombreProducto.KOPR.trim(); //(body.KOPR).trim();
        let cantidad = body.CAPRCO1; //this.state.nombreProducto.CAPRCO1;
        let temp1 = 0;
        let temp2 = 0;

        if (this.state.dataTableProducto.length >= 0) {


            const temp = this.state.dataTableProducto.find((item: any) => item.KOPR == codigo);

            if (temp != null || temp != undefined) {

                temp1 = parseInt(temp.STOCNV1);//que hay
                temp2 = parseInt(cantidad);
                temp2 += temp1;

                cantidad = temp2;
            }

            await ProductoStore.GetPrecioProducto(lista, codigo, cantidad)
                .then((response: any) => {
                    this.setState({ dataPrecio: response.data.objPrecio });
                })
                .catch((error: any) => {

                });


            if (temp != null || temp != undefined) {

                this.refCantidadTabla.state.value = cantidad;
                temp.STOCNV1 = cantidad;
                temp.precio = this.state.dataPrecio.precio;
                temp.total = this.state.dataPrecio.total;

                this.setState({ dataTableProducto: [...this.state.dataTableProducto] })
            }
            else {
                this.bodyProducto = {
                    TIPR: "FPN",
                    KOPR: codigo,
                    NOKOPR: this.state.nombreProducto.NOKOPR,
                    STOCNV1: body.CAPRCO1,
                    precio: this.state.dataPrecio.precio,
                    descuento: this.state.dataPrecio.descuento,
                    valorDescuento: this.state.dataPrecio.valorDescuento,
                    total: this.state.dataPrecio.total
                }
                JSON.stringify(this.bodyProducto);
                this.setState({ dataTableProducto: [...this.state.dataTableProducto, this.bodyProducto] });
            }

            this.calcularTotal();
            this.resetForm.resetFields();
            this.setState(
                {
                    urlImage: img,
                    dataStockSucursal: '',
                    nombreProducto: '',
                    totalUnidades: '',
                    cantidadEmbalaje: '',
                    showInfoProducto: false
                }
            );

            this.refCodigo.focus();

        }



    }

    onChangeDireccion = (e: any) => {
        this.setState({ valueRadio: e.target.value })
    }

    onChangeDocumento = (e: any) => {
        console.log(e.target.value);
        this.setState({ valueRadioDocumento: e.target.value })
    }

    confirmarDireccion() {

        if (this.state.valueRadio == false) {
            return notification.info({
                message: 'Atención!',
                description: 'Debe seleccionar una dirección'
            })
        }

        this.setState({ dataCliente: this.state.valueRadio });
        this.setState({ showModalDireccion: false });
        this.setState({ disabledCodigo: false });
        this.setState({ disabledDescripcion: false });
    }

    cancelarDireccion() {
        this.setState({ showModalDireccion: false });
    }

    loadStockSucursal(e: any) {

        let temp = '';
        let stock = 0;
        ProductoStore.GetStockSucursales(e)
            .then((response: any) => {

                temp = response.data.stock.filter((item: any) => item.KOSU == "CMA").map((item: any) => item.FICHA);
                temp = temp.toString().replace('EMB', '').trim();
                this.setState({ cantidadEmbalaje: temp });
                this.setState({ dataStockSucursal: response.data.stock });
            })
            .catch((error: any) => {

            })
    }

    showModalProductos() {
        this.setState({ showModalProductos: true });
    }

    cancelModalProductos() {
        this.setState({ showModalProductos: false });
    }

    confirmSaveNotaDeVenta() {


        if (this.selected.length == 0) {
            return notification.info({
                message: 'Información',
                description: 'Por favor debe buscar un cliente'
            });
        }
        else if (this.state.dataTableProducto.length == 0) {
            return notification.info({
                message: 'Información',
                description: 'Por favor debe agregar productos al pedido'
            });
        }
        else {
            this.setState({ showModalDocumento: true });
            // confirm({
            //     title: '¿Está seguro que desea guardar la Nota de venta?',
            //     icon: <InfoCircleOutlined />,
            //     okText: 'Sí',
            //     cancelText: 'No',

            //     onOk() {
            //         save();

            //     },
            //     onCancel() {

            //     },

            // });
        }

    }



    saveTipoDocumento() {

        //this.setState({ showModalDocumento: false,dataTableProducto:[]});
        const lista = this.state.dataTableProducto;

        JSON.stringify(lista);

        const body = {
            MAEEDO: {
                EMPRESA: "02",
                TIDO: "COV",
                NUDO: "",
                ENDO: this.state.rutCliente,
                SUENDO: this.selected.SUEN,
                SUDO: "EFE",
                LUVTDO: "EFE",
                KOFUDO: ""
            },
            MAEDDO: {

            },
            ListaProducto: lista

        }

        PedidoStore.saveCotizacion(body)
            .then((response: any) => {
                notification.success({
                    message: 'Registro existoso!',
                    description: response.data.message
                });
                this.setState({ totalNotaVenta: 0 });
                this.setState({ showModalDocumento: false });
                this.setState({ dataTableProducto: [] });
            })
            .catch((error: any) => {

            });
        // let doc = '';

        // if (this.state.valueRadioDocumento != false) {

        //     this.state.valueRadioDocumento == "COV" ? doc = 'Cotización' : doc = "Boleta";
        // }

        // if (this.state.valueRadioDocumento == false) {
        //     return notification.warning({
        //         message: 'Advertencia',
        //         description: 'Por favor debe seleccionar tipo de documento'
        //     });
        // }
        // else {
        //     notification.success({
        //         message: 'Registro existoso',
        //         description: 'La ' + doc + ' ha sido guardada correctamente'
        //     });

        //     this.setState({ showModalDocumento: false });
        // }

    }

    showConfirm(save: any) {
        confirm({
            title: '¿Desea guardar la cotización?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                save()
            },
            onCancel() {

            }
        })
    }

    metodo() {
        alert("funciona!!!")
    }

    generarNotaVenta = async () => {

        if (this.selected.length == 0) {
            return notification.info({
                message: 'Información',
                description: 'Por favor debe buscar un cliente'
            });
        }
        else if (this.state.dataTableProducto.length == 0) {
            return notification.info({
                message: 'Advertencia',
                description: 'Por favor debe agregar productos al pedido'
            });
        }
        else {
            const tipoDoc = this.state.tipoDocumento.toUpperCase();
            if (tipoDoc == "NVV") {
                this.setState({ showModalGuardarDocumento: true });
            }
            else if (tipoDoc == "COV") {

                this.guardarDocumento();
            }
        }
    }

    guardarDocumento = async () => {

        this.setState({ showModalGuardarDocumento: false });
        const lista = this.state.dataTableProducto;
        const usuario = sessionStorage.getItem("usuario");
        const sucursal = sessionStorage.getItem("sucursal");
        let cantidadProductos = 0;
        let listaDetalle: any = [];

        JSON.stringify(lista);

        this.state.dataTableProducto.forEach((element: any) => {
            cantidadProductos += parseInt(element.STOCNV1)
        });




        this.state.dataTableProducto.forEach((item: any) => {
            listaDetalle.push({ KOPR: item.KOPR, CAPRCO1: item.STOCNV1, VANELI: item.total, PPPRBRLT: item.precio, NOKOPR: item.NOKOPR })
        });

        const body = {
            MAEEDO: {
                EMPRESA: "02",
                TIDO: this.state.valueRadioDocumento,
                NUDO: this.state.notaVentaActual,
                ENDO: this.state.rutCliente,
                SUENDO: this.selected.SUEN,
                SUDO: sucursal,
                LUVTDO: sucursal,
                KOFUDO: usuario,
                VANEDO: this.state.totalNotaVenta,
                LISACTIVA: this.selected.LVEN,
                CAPRCO: cantidadProductos,
                CAPRAD: cantidadProductos
            },
            MAEDDO: {

            },
            ListaProducto: lista,
            ListaDetalle: listaDetalle

        }

        await PedidoStore.addPedidoCliente(body)
            .then((response: any) => {
                notification.success({
                    message: 'Registro existoso!',
                    description: response.data.message
                });
                this.setState({ totalNotaVenta: 0 });
                this.setState({ dataTableProducto: [] });
            })
            .catch((error: any) => {

            })

        this.limpiar();
        this.loadNotaVentaActual();
    }


    calcularTotal() {

        let cont = 0;
        this.state.dataTableProducto.forEach((element: any) => {
            cont += element.total;

        });

        this.setState({ totalNotaVenta: cont });

    }

    cancelTipoDocumento() {
        this.setState({ showModalDocumento: false });
    }

    sendAuthorization() {

    }

    showProductos(e: any) {
        if (e.currentTarget.id == "") {
            this.setState({ showCategorias: true });
        }
        else {
            this.setState({ showCategorias: false });
        }
    }

    searchProducto(producto: any) {

        ProductoStore.GetProductoPorCodigo(producto)
            .then((response: any) => {

                this.setState({ dataProducto: response.data.lista });
            })
            .catch((error: any) => {

            });
    }

    handleProducto(e: any) {

        if (e == undefined) {
            this.setState({ dataProducto: this.state.dataSource });
        }
    }

    rowTable(e: any) {
        const imagen = e.KOPR.trim();

        this.loadImageProducto(imagen);
    }

    loadImageProducto(imagen: any) {
        ProductoStore.GetImagenProducto(imagen.trim())
            .then((response: any) => {
                if (response.data.success == false) {
                    this.setState({ urlImagenProducto: img });
                }
                else {
                    this.setState({ urlImagenProducto: response.data.response.m_Uri });
                }

            })
            .catch((error: any) => {

            })
    }

    addProductoCotizacion = async (body: any) => {

        const lista = this.state.dataCliente.LVEN;
        const codigo = (body.KOPR).trim();
        let cantidad = body.CAPRCO1;
        let temp1 = 0;
        let temp2 = 0;

        const temp = this.state.dataTableProducto.find((item: any) => item.KOPR == codigo);

        if (temp != null || temp != undefined) {

            temp1 = parseInt(temp.CAPRCO1);//que hay
            temp2 = parseInt(cantidad);
            temp2 += temp1;

            cantidad = temp2;
        }

        await ProductoStore.GetPrecioProducto(lista, codigo, cantidad)
            .then((response: any) => {
                this.setState({ dataPrecio: response.data.objPrecio });
            })
            .catch((error: any) => {

            });


        if (temp != null || temp != undefined) {

            temp.CAPRCO1 = cantidad;
            temp.precio = this.state.dataPrecio.precio;
            temp.total = this.state.dataPrecio.total

            this.setState({ dataTableProducto: [...this.state.dataTableProducto] })
        }
        else {
            this.bodyProducto = {
                TIPR: "FPN",
                KOPR: codigo,
                NOKOPR: this.state.nombreProducto.NOKOPR,
                STOCNV1: body.CAPRCO1,
                precio: this.state.dataPrecio.precio,
                descuento: this.state.dataPrecio.descuento,
                valorDescuento: this.state.dataPrecio.valorDescuento,
                total: this.state.dataPrecio.total
            }
            JSON.stringify(this.bodyProducto);
            this.setState({ dataTableProducto: [...this.state.dataTableProducto, this.bodyProducto] });
        }

        this.calcularTotal();
    }

    handleCantidad(e: any) {

        if (e.target.value != "") {
            if (this.state.cantidadEmbalaje != "") {
                const emb = parseInt(this.state.cantidadEmbalaje);
                const cant = parseInt(e.target.value);

                const calculo = (cant / emb);

                this.setState({ totalUnidades: calculo.toString() });
            }

        }
        else {
            this.setState({ totalUnidades: '' });
        }




    }

    onChangeNumeroPedido(e: any) {
        const val = e.target.value;
        this.setState({ notaVentaActual: val });
    }

    onChangeTipoDocument(e: any) {
        const val = e.target.value;
        this.setState({ tipoDocumento: val });
    }

    onChangeRutCliente(e: any) {
        const val = e.target.value;
        this.setState({ rutCliente: val });
    }

    //#region Limpiar módulo de ventas 

    limpiarFormulario(limpiar: any) {

        if (this.state.dataTableProducto.length >= 12) {
            confirm({
                title: '¿Desea limpiar el módulo de ventas?',
                icon: <ExclamationCircleOutlined />,
                content: 'Se perderán todos los cambios efectuados.',
                okText: 'Aceptar',
                cancelText: 'Cancelar',
                onOk() {
                    limpiar()
                }
            })
        }
        else {
            this.setState(
                {
                    dataCliente: [],
                    dataTableProducto: [],
                    totalNotaVenta: '0',
                    cantidadEmbalaje: '',
                    nombreProducto: [],
                    disabledDescripcion: true,
                    disabledCantidad: true,
                    disabledCodigo: true,
                    showInfoProducto: false,
                    urlImage: img,
                    rutCliente: '',
                    observacionesCliente: '',
                    condicionesPago: ''
                });
        }

    }

    limpiar() {
        this.setState(
            {
                dataCliente: [],
                dataTableProducto: [],
                totalNotaVenta: '0',
                cantidadEmbalaje: '',
                nombreProducto: [],
                disabledDescripcion: true,
                disabledCantidad: true,
                disabledCodigo: true,
                showInfoProducto: false,
                urlImage: img,
                rutCliente: '',
                observacionesCliente: '',
                condicionesPago: ''
            });
    }

    //#endregion




    cancelarModalDocumentos() {
        this.setState({ showModalDocumentosCliente: false });
    }

    documentoSeleccionado(e: any) {
        PedidoStore.getPedidoSeleccionado(e.TIDO, e.NUDO)
            .then((response: any) => {
                this.setState({ dataCliente: response.data.c });
            })
            .catch((error: any) => {
                console.log(error);
            })

        this.setState({ showModalDocumentosCliente: false, tipoDocumento: e.TIDO });

    }

    searchClientePorNombre(e: any) {

        if (e.target.value == "") {
            return notification.warning({
                message: 'Advertencia',
                description: 'Debe ingresar un nombre'
            });
        }
        ClienteStore.getClientePorNombre(e.target.value)
            .then((response: any) => {

                this.setState({ dataClientePorNombre: response.data })
            })
            .catch((error: any) => {
                console.log(error);
            });
    }

    rowTableCliente(e: any) {

        this.setState({ dataCliente: e, showModalBusquedaCliente: false, disabledCodigo: false, disabledDescripcion: false });
        this.setState({ condicionesPago: e.CPEN, observacionesCliente: e.OBEN, rutCliente: e.KOEN, dataClientePorNombre: [] })
        this.refCondiciones.focus();
        this.refCondiciones.setSelectionRange(0, 0);
    }

    onChangeTableProducto(e: any) {
        const val = e.target.value;
        this.setState({ tipoDocumento: val });
    }

    onRowTablePendientes(e: any) {
        PedidoStore.getDetallePedidoPendiente(e.NUDO)
            .then((response: any) => {
                this.setState({ dataTableProducto: response.data });
                this.calcularTotal();
            })
            .catch((error: any) => {
                console.log(error);
            })
        this.setState({ showModalPedidosPendientes: false });

    }

    cancelTablePendientes() {
        this.setState({ showModalPedidosPendientes: false });
    }

    updateCantidad = async (e: any) => {


        if (e.STOCNV1 == "") {
            return notification.warning({
                message: 'Advertencia',
                description: 'Por favor ingresar una cantidad'
            });
        }
        const lista = this.selected.LVEN;
        const codigo = e.KOPR;
        let cantidad = e.STOCNV1;

        const temp = this.state.dataTableProducto.find((item: any) => item.KOPR == codigo);

        await ProductoStore.GetPrecioProducto(lista, codigo, cantidad)
            .then((response: any) => {
                this.setState({ dataPrecio: response.data.objPrecio });
            })
            .catch((error: any) => {

            });
        temp.CAPRCO1 = cantidad;
        temp.precio = this.state.dataPrecio.precio;
        temp.total = this.state.dataPrecio.total

        this.setState({ dataTableProducto: [...this.state.dataTableProducto] })

        this.calcularTotal();
        this.refCodigo.focus();

    }

    onChangeCantidad(e: any, text: any, aux: any) {
        const valor = this.state.dataTableProducto.find((item: any) => item.KOPR == e.KOPR);
        valor.STOCNV1 = aux.target.value;
        return valor;
    }

    eliminarRegistro = async (e: any) => {


        const newArray = this.state.dataTableProducto.filter((item: any) => item.KOPR !== e.KOPR);
        await this.setState({
            dataTableProducto: newArray
        });


        this.calcularTotal();
    }

    handleGuardarDocumento() {

        if (this.state.valueRadioDocumento) {
            this.guardarDocumento();
        }
        else {
            return notification.warning({
                message: 'Advertencia!',
                description: 'Por favor seleccionar una opción'
            })
        }
    }

    handleCancelarDocumento() {
        this.setState({ showModalGuardarDocumento: false, valueRadioDocumento: false });
    }

    getPendienteUsuario() {
        const usuario = sessionStorage.getItem('usuario');
        PedidoStore.getPedidosPendientesUsuarios(usuario)
            .then((response: any) => {
                this.setState({ dataPendientesUsuario: response.data, showModalPendientesUsuario: true });
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    handleCancelPendientesUsuario() {
        this.setState({ showModalPendientesUsuario: false });
    }

    onRowTablePendientesUsuario(e:any){
        
        const id = e.IDMAEEDO;
        
        this.setState({dataCliente:e, rutCliente:e.KOEN, condicionesPago:e.CPEN, observacionesCliente:e.OBEN});

        PedidoStore.getDetallePendienteUsuario(id)
        .then((response:any)=>{
            
            this.setState({showModalPendientesUsuario:false, dataTableProducto:response.data});
            this.calcularTotal();
        })
        .catch((error:any)=>{
            console.log(error);
        })
        
        
    }

    onChangeCondicionesPago(e: any) {
        const valor = e.target.value;
        this.setState({ condicionesPago: valor });
    }

    onChangeObservaciones(e: any) {
        const valor = e.target.value;
        this.setState({ observacionesCliente: valor });
    }

    saveDocumentoPendienteUsuario=async()=>{
        //this.setState({ showModalGuardarDocumento: false });
        const lista = this.state.dataTableProducto;
        const usuario = sessionStorage.getItem("usuario");
        const sucursal = sessionStorage.getItem("sucursal");
        let cantidadProductos = 0;
        let listaDetalle: any = [];

        JSON.stringify(lista);

        this.state.dataTableProducto.forEach((element: any) => {
            cantidadProductos += parseInt(element.STOCNV1)
        });

        this.state.dataTableProducto.forEach((item: any) => {
            listaDetalle.push({ KOPR: item.KOPR, CAPRCO1: item.STOCNV1, VANELI: item.total, PPPRBRLT: item.precio, NOKOPR: item.NOKOPR })
        });

        const body = {
            MAEEDO: {
                EMPRESA: "02",
                TIDO: "NVV",
                NUDO: this.state.notaVentaActual,
                ENDO: this.state.rutCliente,
                SUENDO: this.selected.SUEN,
                SUDO: sucursal,
                LUVTDO: sucursal,
                KOFUDO: usuario,
                VANEDO: this.state.totalNotaVenta,
                LISACTIVA: this.selected.LVEN,
                CAPRCO: cantidadProductos,
                CAPRAD: cantidadProductos
            },
            MAEDDO: {

            },
            ListaProducto: lista,
            ListaDetalle: listaDetalle

        }

        await PedidoStore.saveDocumentoPendiente(body)
            .then((response: any) => {
                notification.success({
                    message: 'Registro existoso!',
                    description: response.data.message
                });
                this.setState({ totalNotaVenta: 0 });
                this.setState({ dataTableProducto: [] });
            })
            .catch((error: any) => {

            })

        this.limpiar();
        this.loadNotaVentaActual();
    }


    public render() {

        const columns = [
            { title: 'COD', dataIndex: 'KOPR', key: 'KOPR', width: '6%' },
            { title: 'Detalle', dataIndex: 'NOKOPR', key: 'NOKOPR', width: '20%' },
            { title: 'Cantidad', dataIndex: 'STOCNV1', key: 'STOCNV1', width: '8%', editable: true, render: (text: any, record: any) => (<Input bordered={false} onChange={this.onChangeCantidad.bind(this, record, text)} id="cantidad" ref={r => this.refCantidadTabla = r} defaultValue={record.STOCNV1} onPressEnter={this.updateCantidad.bind(this, record, text)} />) },
            { title: 'Precio', dataIndex: 'precio', key: 'precio', width: '8%' },
            { title: 'Dscto', dataIndex: 'descuento', key: 'descuento', width: '8%' },
            { title: '', dataIndex: 'valorDescuento', key: 'valorDescuento', width: '8%' },
            { title: 'Total', dataIndex: 'total', key: 'total', width: '10%' },
            {
                title: 'Acción', dataIndex: '', width: '10%',
                render: (text: any, record: any) => (
                    <a onClick={this.eliminarRegistro.bind(this, record)}><DeleteOutlined /></a>

                    // <Popconfirm title="Estas seguro?">
                    //     <a>Eliminar</a>
                    // </Popconfirm>
                )
            }

        ];

        const columnsStock = [
            { title: 'Sucursal', dataIndex: 'KOSU', key: 'KOSU' },
            { title: 'Bodega', dataIndex: 'KOSU', key: 'KOSU' },
            { title: 'Físico', dataIndex: 'STFI1', key: 'STFI1' },
            { title: 'Embalaje', dataIndex: 'FICHA', key: 'FICHA' }
        ];

        const columnsDirecciones = [
            {
                title: '',
                key: 'action',
                render: (text: any, record: any, index: any) => (
                    <Radio.Group onChange={this.onChangeDireccion} value={this.state.valueRadio}>
                        <Radio value={text} ></Radio>
                    </Radio.Group>

                ),
            },
            { title: 'Cod', dataIndex: 'SUEN', key: 'SUEN' },
            { title: 'Direccion', dataIndex: 'DIEN', key: 'DIEN' }
        ];

        const columnsProductosOferta = [
            { title: 'COD', dataIndex: 'KOPR', key: 'KOPR' },
            { title: 'Nombre', dataIndex: 'NOKOPR', key: 'NOKOPR' },
            { title: 'Stock', dataIndex: 'STFI1', key: 'STFI1' },
            {
                title: 'Cantidad', dataIndex: 'CAPRCO1', key: 'CAPRCO1', editable: true, width: '15%', render: (text: any, row: any, value: any) => {
                    return (<Form.Item name="CAPRCO1"><Input type="number" min={0} /></Form.Item>)
                }

            },
            {
                title: 'Acciones', key: 'action', render: (text: any, record: any, row: any) => (
                    <Button type="primary" danger shape="round" onClick={this.addProductoCotizacion.bind(this, record)}>Agregar producto</Button>
                )
            }
        ];

        const columnsDocumentosCliente = [
            { title: 'TD', dataIndex: 'TIDO', key: 'TIDO' },
            { title: 'N° Pedido', dataIndex: 'NUDO', key: 'NUDO' },
            { title: 'Rut', dataIndex: 'ENDO', key: 'ENDO' },
            { title: 'Sucursal', dataIndex: 'SUDO', key: 'SUDO' },
            { title: 'Fecha emisión', dataIndex: 'FEEMDO', key: 'FEEMDO' },
            { title: 'Total', dataIndex: 'VABRDO', key: 'VABRDO' },
        ];

        const columnsDatosCliente = [
            { title: 'Rut', dataIndex: 'KOEN', key: 'KOEN' },
            { title: 'Suc', dataIndex: 'SUEN', key: 'SUEN' },
            { title: 'Nombre', dataIndex: 'NOKOEN', key: 'NOKOEN' },
            { title: 'Dirección', dataIndex: 'DIEN', key: 'DIEN' },
        ];

        const columnsDocumentosPendientes = [
            { title: 'TD', dataIndex: 'TIDO', key: 'TIDO' },
            { title: 'Número', dataIndex: 'NUDO', key: 'NUDO' },
            { title: 'Suc. Doc', dataIndex: 'SUDO', key: 'SUDO' },
            { title: 'Suc. Ent.', dataIndex: 'SUENDO', key: 'SUENDO' },
            { title: 'F. Emisión', dataIndex: 'FEEMDO', key: 'FEEMDO' },
            { title: 'F. Vencim.', dataIndex: 'FEEMDO', key: 'FEEMDO' },
            { title: 'Monto', dataIndex: 'VABRDO', key: 'VABRDO' },
        ];

        const columnsPendientesUsuario = [
            { title: 'TD', dataIndex: 'TIDO', key: 'TIDO' },
            { title: 'Suc.', dataIndex: 'SUDO', key: 'SUDO' },
            { title: 'Fecha', dataIndex: 'FEEMDO', key: 'FEEMDO' },
            { title: 'Resp.', dataIndex: 'KOFUDO', key: 'KOFUDO' },
            {title:'Valor', dataIndex:'VABRDO', key:'VABRDO'},
            {title:'Nombre entidad', dataIndex:'NOKOEN', key:'NOKOEN'}
        ];

        this.selected = this.state.dataCliente;
        const producto = this.state.nombreProducto;

        return (
            <div>
                <div style={{ backgroundColor: ' #f1efee ' }}>
                    <h3>Notas de ventas</h3>
                    <div style={{ width: 'auto' }}>
                        <Row gutter={24}>
                            <Col span={2}>
                                TD
                                <Input style={{ textTransform: 'uppercase' }} onPressEnter={this.searchDocumento} onChange={this.onChangeTipoDocument.bind(this)} value={this.state.tipoDocumento} type="text" placeholder="TD" />
                            </Col>
                            <Col span={3}>
                                Número
                                <Input value={this.state.notaVentaActual} onChange={this.onChangeNumeroPedido.bind(this)} onPressEnter={this.searchNumeroPedido} type="text" />
                            </Col>
                            <Col span={4}>
                                Rut
                                <Input onPressEnter={this.searchCliente} onChange={this.onChangeRutCliente.bind(this)} value={this.state.rutCliente} placeholder="Ingresar rut cliente" />
                            </Col>

                            <Col span={3}>
                                Emisión
                                <Input value={this.state.currentDate && this.state.currentDate} readOnly />
                            </Col>
                            <Col span={3}>
                                Vencimiento
                                <Input value={this.state.currentDate && this.state.currentDate} readOnly />

                            </Col>
                            <Col span={4}>
                                Lista Precio
                                <Input value={this.selected && this.selected.LVEN} readOnly />
                            </Col>
                            <Col span={2}>
                                Sucursal
                                <Input value={this.state.sucursalUsuario && this.state.sucursalUsuario} />
                                {/* <Input value={this.selected && this.selected.SUEN} /> */}
                            </Col>
                            <Col span={2}>
                                Modalidad
                                <Input value={this.state.modalidad && this.state.modalidad} />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={5} >
                                Cliente
                                <Input value={this.selected && this.selected.NOKOEN} type="text" readOnly />
                            </Col>
                            <Col span={6} >
                                Dirección
                                <Input value={this.selected && this.selected.DIEN} type="text" readOnly />
                            </Col>
                            <Col span={6}>
                                Condiciones de pago
                                <Input id="CPEN" ref={r => this.refCondiciones = r} onChange={this.onChangeCondicionesPago.bind(this)} type="text" value={this.state.condicionesPago && this.state.condicionesPago} />
                            </Col>
                            <Col span={6}>
                                Observaciones
                                <Input id="OBEN" value={this.state.observacionesCliente && this.state.observacionesCliente} />
                            </Col>
                        </Row>
                    </div>
                </div>

                <div style={{ background: '#E7E9EB', height: 'auto' }}>
                    <div style={{ width: 'auto' }}>
                        <Tooltip title="Productos sustitutos" >
                            <Button type='primary' shape='circle' style={{ margin: '5px' }} size="large" icon={<ShoppingOutlined style={{ fontSize: '30px' }} />}>

                            </Button>

                        </Tooltip>
                        <Tooltip title="Productos similares" >
                            <Button type='primary' shape='circle' style={{ margin: '5px' }} size="large" icon={<ShoppingOutlined style={{ fontSize: '30px' }} />}>

                            </Button>

                        </Tooltip>
                        <Tooltip title="Productos relacionados" >
                            <Button type='primary' shape='circle' style={{ margin: '5px' }} size="large" icon={<ShoppingOutlined style={{ fontSize: '30px' }} />}>

                            </Button>

                        </Tooltip>
                        <Tooltip title="Top 100" >
                            <Button type='primary' shape='circle' style={{ margin: '5px' }} size="large" icon={<ShoppingOutlined style={{ fontSize: '30px' }} />}>

                            </Button>

                        </Tooltip>
                        <Tooltip title="Ofertas" >
                            <Button type='primary' shape='circle' style={{ margin: '5px' }} size="large" icon={<ShoppingOutlined style={{ fontSize: '30px' }} />}>

                            </Button>

                        </Tooltip>
                    </div>
                    <Row gutter={24} style={{ marginLeft: '5px' }}>
                        <Col span={18}>
                            <Form onFinish={this.addProducto} ref={r => this.resetForm = r}>
                                <Row gutter={24}>
                                    <Col span={3}>
                                        <Form.Item name="KOPR" id="KOPR">
                                            Código
                                            <Select ref={r => this.refCodigo = r} showSearch showArrow={false} onChange={this.handleCodigo} allowClear disabled={this.state.disabledCodigo} value={this.state.nombreProducto && this.state.nombreProducto.KOPR} filterOption={(input: any, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                                {this.state.dataProductos && this.state.dataProductos.map((item: any) => <Option value={item.KOPR} key={item.KOPR} >{item.KOPR}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>

                                        <Form.Item name="NOKOPR" id="NOKOPR">
                                            Descripción
                                            <Select showSearch allowClear showArrow={false} disabled={this.state.disabledDescripcion} onChange={this.handleDescripcion} value={producto && producto.NOKOPR} filterOption={(input: any, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                                {this.state.dataProductos && this.state.dataProductos.map((item: any) => <Option value={item.KOPR} key={item.KOPR}>{item.NOKOPR}</Option>)}
                                            </Select>
                                            {/* <Input value={this.state.nombreProducto && this.state.nombreProducto.NOKOPR} readOnly /> */}
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        Cantidad
                                        <Form.Item name="CAPRCO1" id="CAPRCO1" initialValue="">

                                            <Input disabled={this.state.disabledCantidad} ref={r => this.refCantidad = r} onChange={this.handleCantidad.bind(this)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>

                                        <Form.Item name="STFI1" id="STFI1">
                                            UNI x EMB
                                            <Input type="text" readOnly defaultValue={this.state.cantidadEmbalaje} value={this.state.cantidadEmbalaje && this.state.cantidadEmbalaje} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>

                                        <Form.Item name="TOTAL" id="TOTAL" initialValue="">
                                            Tot. embalajes
                                            <Input readOnly value={this.state.totalUnidades && this.state.totalUnidades} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item>
                                            <Button type="primary" style={{ marginBottom: '20px', marginTop: '20px' }} htmlType="submit" shape="round">Agregar</Button>
                                        </Form.Item>
                                    </Col>

                                    {/* <Col span={1}>
        <Form.Item>
            <Button type="primary" style={{ marginBottom: '20px', marginTop: '20px' }} htmlType="submit" shape="round">Agregar</Button>
        </Form.Item>
    </Col> */}

                                </Row>
                            </Form>
                            <Row>
                                <Col span={24}>
                                    <Table components={{
                                        body: {

                                        }
                                    }} columns={columns} dataSource={this.state.dataTableProducto} onRow={(record: any) => { return { onKeyDown: this.onChangeTableProducto.bind(this, record) } }} pagination={false} size="middle" >
                                    </Table>
                                </Col>
                            </Row>
                            <div style={{ float: 'left' }}>
                                <Tooltip title="Documentos pendientes usuario"><Button type='primary' shape='circle' style={{ margin: '10px' }} size="large" onClick={this.getPendienteUsuario.bind(this)} icon={<ContainerOutlined style={{ fontSize: '30px' }} />}></Button> </Tooltip>
                                <Tooltip title="Documentos pendientes entidad"><Button type='primary' shape='circle' style={{ margin: '10px' }} size="large" icon={<FolderOpenOutlined style={{ fontSize: '30px' }} />}></Button></Tooltip>
                                <Tooltip title="Guardar documento"><Button type='primary' shape='circle' size="large" onClick={this.saveDocumentoPendienteUsuario.bind(this)} style={{ margin: '10px' }} icon={<SaveOutlined style={{ fontSize: '30px' }} />}></Button></Tooltip>
                            </div>
                            <div style={{ float: 'right' }}>
                                Total<Input style={{ float: 'right' }} value={`$ ${this.state.totalNotaVenta && this.state.totalNotaVenta}`} readOnly />
                            </div>
                            <Row>

                                {/* <Col>
                                    Total<Input style={{ float: 'right' }} value={`$ ${this.state.totalNotaVenta && this.state.totalNotaVenta}`} readOnly />
                                </Col> */}
                            </Row>
                        </Col>
                        <Col span={5}>
                            <Card style={{ width: 280, marginTop: 16, height: 370 }} size="default"
                                title={this.state.nombreProducto && this.state.nombreProducto.NOKOPR}
                            >
                                <div style={{ display: 'block', textAlign: 'center' }}>
                                    <Image style={{ width: '80px', height: '80px', margin: '0 auto' }} src={this.state.urlImage} />
                                </div>
                                {this.state.showInfoProducto ? <div style={{ textAlign: 'center' }}>
                                    {this.state.nombreProducto && this.state.nombreProducto.NOKOPRRA}
                                    <br />
                                    <div style={{ textAlign: 'center' }}>Stock</div>
                                    <div style={{ textAlign: 'center' }}>
                                        {this.state.dataStockSucursal && this.state.dataStockSucursal.map((item: any) =>
                                            <Row gutter={12}>
                                                <Col span={8}>
                                                    {item.KOSU}
                                                </Col>
                                                <Col span={8}>
                                                    {item.STFI1}
                                                </Col>
                                                {/* <Col span={5}>
                                                {item.FICHA}
                                            </Col> */}

                                            </Row>)}
                                    </div>
                                    <hr />
                                    UNID/EMB: {this.state.cantidadEmbalaje && this.state.cantidadEmbalaje}

                                </div> : []}
                            </Card>
                            <br />
                            <div style={{ textAlign: 'center' }}>
                                <Button style={{ margin: '10px' }} type="primary" danger shape="round" onClick={this.generarNotaVenta}>Generar</Button>
                                <Button type="primary" shape="round" onClick={() => this.limpiarFormulario(this.limpiar)}>Limpiar</Button>
                            </div>

                        </Col>

                    </Row>
                    {/* <br />
                    <div style={{ width: 'auto', borderStyle: 'groove' }}>
                    <ContainerOutlined style={{fontSize:'30px'}} />
                    <FolderOpenOutlined style={{fontSize:'30px'}} />
                    </div> */}



                    <Row gutter={10} style={{ float: 'right' }}>
                        {/* <Col span={6}>
                            <Button style={{ float: 'right' }} type="primary" shape="round">Documentos pendientes</Button>
                        </Col>
                        <Col span={3}>
                            <Button style={{ float: 'right' }} type="primary" shape="round" onClick={this.confirmSaveNotaDeVenta}>Ver Cotización</Button>
                        </Col> */}
                        {/* <Col>
                            <Button type="primary" danger shape="round" onClick={this.generarNotaVenta}>Generar</Button>
                        </Col> */}
                        {/* <Col span={4}>
                            <Button type="primary" danger shape="round" onClick={this.showModalProductos}>Consultar</Button>
                        </Col> */}
                        {/* <Col>
                            <Button type="primary" shape="round" onClick={() => this.limpiarFormulario(this.limpiar)}>Limpiar</Button>
                        </Col> */}

                    </Row>

                </div>

                {/* MODAL  */}
                <Modal title="Seleccionar dirección cliente" visible={this.state.showModalDireccion} onOk={this.confirmarDireccion} onCancel={this.cancelarDireccion} destroyOnClose={true} >
                    <div>
                        <Table columns={columnsDirecciones} dataSource={this.state.dataDirecciones} size="small">
                        </Table>
                    </div>
                </Modal>

                <Modal className="modal-productos" width={1000} style={{ top: 20 }} title="Productos" visible={this.state.showModalProductos} destroyOnClose={true} onCancel={this.cancelModalProductos} footer={false}>

                    {this.state.showCategorias && this.state.showCategorias ?
                        <div>
                            <Row gutter={24}>

                                <Col span={8}>
                                    <a onClick={this.showProductos} id="1">
                                        <img
                                            width={100}
                                            alt="logo"
                                            src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                        />
                                    </a>
                                    Categoria 1
                                </Col>
                                <Col span={8}>
                                    <a onClick={this.showProductos} id="2">
                                        <img

                                            width={100}
                                            alt="logo"
                                            src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                        />
                                    </a>
                                    Categoria 2
                                </Col>
                                <Col span={8}>
                                    <a onClick={this.showProductos} id="3">
                                        <img
                                            width={100}
                                            alt="logo"
                                            src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                        />

                                    </a>
                                    Categoria 3
                                </Col>

                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <a onClick={this.showProductos} id="4">
                                        <img
                                            width={100}
                                            alt="logo"
                                            src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                        />
                                    </a>
                                    Categoria 4
                                </Col>
                                <Col span={8}>
                                    <a onClick={this.showProductos} id="5">
                                        <img
                                            width={100}
                                            alt="logo"
                                            src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                        />
                                    </a>
                                    Categoria 5
                                </Col>
                                <Col span={8}>
                                    <a onClick={this.showProductos} id="6">
                                        <img
                                            width={100}
                                            alt="logo"
                                            src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                        />
                                    </a>
                                    Categoria 6
                                </Col>

                            </Row>
                        </div>
                        : <div>


                            {/* <Button style={{ float: 'right' }} type="primary" shape='round' danger>Agregar producto</Button> */}

                            <Row gutter={24}>
                                <Col span={6}>
                                    <Button type="primary" shape="round" onClick={this.showProductos}>Volver</Button>

                                </Col>
                                <Col span={6}>
                                    <img src={this.state.urlImagenProducto} width={200} height={200} />
                                </Col>
                                <Col span={12}>
                                    <Select style={{ width: '100%' }} onChange={this.handleProducto} onSelect={this.searchProducto} placeholder="Buscar nombre producto" showSearch allowClear showArrow={false} value={this.state.dataSource && this.state.dataSource.NOKOPR} filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                        {this.state.dataSource && this.state.dataSource.map((item: any) => <Option value={item.KOPR} key={item.KOPR}>{item.NOKOPR}</Option>)}
                                    </Select>
                                </Col>
                            </Row>

                            <Table size="small" dataSource={this.state.dataProducto} columns={columnsProductosOferta} onRow={(record: any) => { return { onClick: this.rowTable.bind(this, record) } }}>
                            </Table>
                        </div>}







                    {/* <List.Item>
                            <Typography.Text>Nombre: {this.selected.NOKOEN}</Typography.Text>
                            <Typography.Text>Rut: {this.selected.KOEN}</Typography.Text>
                            <Typography.Text>Dirección: {this.selected.DIEN}</Typography.Text>
                            <Typography.Text>Cantidad: {this.selected.DIEN}</Typography.Text>
                            <Input type="number"/>
                        </List.Item> */}


                    {/* <Table size="small" dataSource={this.state.dataProductosOfertas} columns={columnsProductosOferta}>
                    </Table> */}
                    {/* <Tabs defaultActiveKey="1">
                        <TabPane tab="Liquidación" key="1">
                            Content of Tab Pane 1
                        </TabPane>
                        <TabPane tab="Top ventas" key="2">
                            Content of Tab Pane 2
                        </TabPane>
                        <TabPane tab="Ofertas" key="3">
                            <Table size="small" dataSource={this.state.dataProductosOfertas} columns={columnsProductosOferta}>
                            </Table>
                        </TabPane>
                    </Tabs> */}
                </Modal>

                <Modal title="Detalle cotización" visible={this.state.showModalDocumento} destroyOnClose={true} onOk={() => this.showConfirm(this.saveTipoDocumento)} okText="Guardar" onCancel={this.cancelTipoDocumento}>

                    <List size="default" bordered header="Datos cliente">
                        <List.Item>
                            <Typography.Text>Nombre: {this.selected.NOKOEN}</Typography.Text>
                            <Typography.Text>Rut: {this.selected.KOEN}</Typography.Text>
                            <Typography.Text>Dirección: {this.selected.DIEN}</Typography.Text>
                        </List.Item>
                    </List>
                    <br />
                    <List itemLayout="horizontal" bordered header="Detalle productos"
                        dataSource={this.state.dataTableProducto}
                        renderItem={(item: any) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar src={() => this.loadImage(item.KOPR)} />}
                                    title={item.NOKOPR}
                                    description={`Cantidad: ${item.STOCNV1} Total: ${item.total}`}
                                >

                                </List.Item.Meta>

                            </List.Item>
                        )
                        }
                    >

                    </List>
                    {/* <Descriptions bordered>
                        <Descriptions.Item label="Descripción producto">
                            Cantidad: {producto.STOCNV1}
                        </Descriptions.Item>
                    </Descriptions> */}

                    {/* <Radio.Group onChange={this.onChangeDocumento} value={this.state.valueRadioDocumento}>
                        <Space direction="vertical">
                            <Radio value="COV" key="COV">Cotización</Radio>
                            <Radio value="BLV" key="BLV">Boleta</Radio>
                            <Radio value="NVV" key="NVV">Nota de venta</Radio>
                        </Space>
                    </Radio.Group>
                     */}
                </Modal>

                <Modal visible={this.state.showModalDocumentosCliente} footer={false} width={650} title="Documentos cliente" destroyOnClose={true} onCancel={this.cancelarModalDocumentos.bind(this)}>
                    <Table columns={columnsDocumentosCliente} dataSource={this.state.dataDocumentosCliente} pagination={false} onRow={(record: any) => { return { onDoubleClick: this.documentoSeleccionado.bind(this, record) } }}>

                    </Table>
                </Modal>
                <Modal title="Autorización" visible={this.state.showModalAutorizacion} destroyOnClose={true}>
                    <p>Ingresar clave de autorización</p>

                    Clave: <Input type="password" />
                </Modal>

                <Modal title="Búsqueda de cliente" visible={this.state.showModalBusquedaCliente} width={750} footer={false} destroyOnClose={true} onCancel={this.cancelModalCliente.bind(this)}>
                    <Row gutter={24}>
                        <Col span={6}>
                            Rut
                            <Input onPressEnter={this.searchClienteModal.bind(this)} />
                        </Col>
                        <Col span={18}>
                            Nombre
                            <Input onPressEnter={this.searchClientePorNombre.bind(this)} />
                        </Col>
                    </Row>
                    <br />
                    <div style={{ borderStyle: 'groove' }}>
                        <Table columns={columnsDatosCliente} size="small" pagination={false} dataSource={this.state.dataClientePorNombre} style={{ height: '250px' }} onRow={(record: any) => { return { onClick: this.rowTableCliente.bind(this, record) } }}>

                        </Table>
                    </div>
                </Modal>

                {/* MODAL DOCUMENTOS PENDIENTES */}
                <Modal title="Documentos pendientes" visible={this.state.showModalPedidosPendientes} width={650} footer={false} destroyOnClose={true} onCancel={this.cancelTablePendientes.bind(this)} >
                    <Table dataSource={this.state.dataPedidosPendientes} columns={columnsDocumentosPendientes} size="middle" pagination={false} onRow={(record: any) => { return { onClick: this.onRowTablePendientes.bind(this, record) } }}>

                    </Table>
                </Modal>

                <Modal title="Desea guardar el documento como:" destroyOnClose={true} closable={true} onCancel={this.handleCancelarDocumento.bind(this)} visible={this.state.showModalGuardarDocumento} footer={[
                    <Button key="submit" type="primary" onClick={this.handleGuardarDocumento.bind(this)} >
                        Aceptar
                    </Button>,
                    <Button key="back" onClick={this.handleCancelarDocumento.bind(this)}>
                        Cancelar
                    </Button>

                ]}>
                    <Radio.Group onChange={this.onChangeDocumento} value={this.state.valueRadioDocumento}>
                        <Space direction="vertical">
                            <Radio value="NVV" key="NVV">Nota de venta</Radio>
                            <Radio value="COV" key="COV">Cotización</Radio>
                        </Space>
                    </Radio.Group>
                </Modal>

                <Modal visible={this.state.showModalPendientesUsuario} title="Documentos pendientes usuario" destroyOnClose={true} onCancel={this.handleCancelPendientesUsuario.bind(this)} width={650} footer={false}>
                    <Table dataSource={this.state.dataPendientesUsuario} pagination={false} size='middle' columns={columnsPendientesUsuario} onRow={(record: any) => { return { onDoubleClick: this.onRowTablePendientesUsuario.bind(this, record) } }}>

                    </Table>
                </Modal>
            </div>

        )
    }

}
