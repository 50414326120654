
//URL API TEST
//export const apiUrl = "http://localhost:55731/api/";
export const HEADERS = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        responseType: 'json',
        accept: 'application/json'
    }
};
//URL API PRODUCCION
export const apiUrl = "http://zonaclientes.cl/rest_my_home/api/";